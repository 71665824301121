.logoImage {
    width: 100%;
    max-width: 300px;
    max-height: 100px;
}

.loginDialogText {
    max-width: 300px;
    height: fit-content;
    margin: 20px auto 0px auto!important;
    text-align: center;
}