#mainImage {
    z-index: 0;
    width: 100%;
    object-fit: cover;
    height: 606px;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
}

.pricingInfo .tryPlacecardButtonGroup {
    margin-inline: auto;
}

.gradientCover {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    background-image: linear-gradient(to right,
            var(--main),
            var(--main) 40%,
            rgba(255, 255, 255, 0) 80%);
    z-index: 1;
    position: absolute;
    width: 80%;
    height: 606px;
    animation-name: slideLeft;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
}

.homeTitle {
    color: white;
    letter-spacing: 1px;
    font-size: 48px;
    margin: 0px;
    font-family: 'Arvo', sans-serif;
}

.introSection {
    margin: auto auto auto 10%;
    text-align: left;
    width: 360px;
    display: flex;
    flex-direction: column;
}

.introSection p {
    font-family: 'Lato', sans-serif;
    font-size: 21px;
}

.introduction em {
    font-weight: bold;
    text-decoration: underline;
    font-style: normal;
}

.tryPlacecardButtonGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 300px;
}


.tryPlacecardButtonGroup button {
    font-family: 'Ubuntu', sans-serif;
    font-size: 24px;
    margin-bottom: 14px;
    box-shadow: 10px 10px 0px 0px rgb(65 65 65 / 7%) !important;
}

@keyframes slideLeft {
    from {
        left: -1500px;
    }

    to {
        left: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}

.howItWorksInstructions {
    display: flex;
    width: 98%;
    max-width: 1500px;
    margin: 30px auto;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: row;
    padding-left: 40px;
}

.stepCard {
    box-shadow: 4px 4px 8px rgb(0 0 0 / 8%);
    border-radius: 7px;
    border: 1px solid rgb(0 0 0 / 8%);
    padding: 20px;
    text-align: left;
    position: relative;
    background: white;
    box-sizing: border-box;
    max-width: 320px;
    min-width: 320px;
    margin: auto;
    height: 250px;
    z-index: 1;
}

.instructionStep {
    position: relative;
    flex: 1;
}

.stepNumber {
    font-size: 170px;
    font-weight: bold;
    color: var(--main);
    position: absolute;
    /* moves the numbers under step card */
    left: -367px;
    top: 0;
    bottom: 0;
    right: 0;
    font-family: Arvo, sans-serif;
    font-weight: bold;
}

.separatingLine {
    width: 98%;
    height: 4px;
    border: none;
    background-color: var(--highlight);
    box-shadow: 2px 2px 2px lightgrey;
}

.smallTitle {
    font-size: 32px;
    letter-spacing: 1px;
}

.howToIcons {
    height: 32px;
    width: 32px;
    color: var(--secondary);
    fill: var(--secondary);
}

#uploadIcon {
    stroke-width: 26px;
    stroke: var(--secondary);
}


.stepCardTitle {
    color: var(--secondary);
    margin: 10px 10px;
}

.pricingText {
    text-align: left !important;
    max-width: 60vw;
    margin-right: auto !important;
    margin-left: auto !important;
}

.pricingImg {
    max-width: 25vw;
}

#pricingTitle {
    font-size: 32px;
    color: var(--secondary);
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}

.signupBtn {
    font-size: 1rem !important;
}



.pricingBtn {
    margin-top: 10px !important;
    display: block !important;
    font-size: 75% !important;
}

.headingGroup {
    display: flex;
    align-items: center;
}



.introduction,
.control {
    display: flex;
    flex-direction: row;
    margin-block: 140px;
}

.introduction .description {
    margin-left: 60px;
}

.control .description {
    margin-right: 60px;
}

.descriptionImg {
    max-width: 669px;
    border-radius: 8px;
    box-shadow: 10px 10px 0px 0px rgba(207, 207, 207, .3);


}

.price .tryPlacecardButtonGroup {
    margin-inline: auto;
}

.mainContent {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    color: #5E5E5E;
    text-align: left;
}

.mainContent .smallTitle {
    font-family: 'Arvo', sans-serif;
    font-weight: 38px;
    color: var(--main);
}

.mainContent .centeredContent {
    margin-inline: auto;
    max-width: 1200px;
    justify-content: center;
    align-items: center;
    padding-inline: 10%;
    padding-top: 15px;
}

.pricingInfo {
    background: rgba(214, 214, 214, .2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-block: 40px 80px;
}

.pricingInfo .checkList {
    font-size: 24px;
    padding-inline: 143px;
    margin-top: 28px;
}

.pricingInfo .price {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #5E5E5E;
    font-size: 48px;
    margin-block: 0 24px;
}

.pricingInfo .disclaimer {
    font-size: 18px;
    margin-top: 32px;
}


.pricingInfo .checkList section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


.pricingInfo .checkList p {
    margin-block: 8px;
    margin-left: 1ch;
}

.pricingInfo .card {
    background: #fff;
    box-shadow: 10px 10px 0px 0px rgba(207, 207, 207, .3);
    width: 100%;
    max-width: 649px;
    padding-block: 40px;
    border-radius: 8px;
}

.missionStatement {
    font-family: 'Ubuntu', sans-serif;
    font-size: 36px;
    color: #535353;
    text-align: center;
    max-width: 936px;
    margin-inline: auto;
    padding-inline: 60px;
    margin-block: 140px;
}

.missionStatement img {
    max-width: 131px;
}


@media only screen and (max-width: 1200px) {
    .howItWorksInstructions {
        flex-direction: column;
        align-content: space-between;

    }

    .descriptionImg {
        max-width: 50vw;
    }

    .gradientCover {
        background-image: linear-gradient(to bottom,
                var(--mainAlpha) 74%,
                rgba(255, 255, 255, 0) 93%);
        width: 100%;
    }

    .instructionStep {
        margin: 30px auto;
    }

    .introSection {
        width: fit-content;
    }
}


@media only screen and (max-width: 768px) {
    .introSection .tryPlacecardButtonGroup {
        margin-inline: 62px;

    }

    .introSection .tryPlacecardButtonGroup .small {
        color: #a1a1a1;
    }

    .eyeCatcher p {
        font-family: "Arvo", sans-serif;
        font-size: 16px;
        text-align: left;
        margin-inline: 11px;
        margin-top: 32px;
        margin-bottom: 22px;
        color: black;
    }

    .eyeCatcher p,
    .mainContent p,
    .mainContent li {
        line-height: 26px;
    }

    .missionStatement p {
        line-height: 32px;
    }

    .tryPlacecardButtonGroup {
        max-width: unset;
    }

    .mainContent .centeredContent {
        flex-direction: column;
    }

    .introSection {
        text-align: center;
        margin-inline: 18px;
    }

    .homeTitle {
        font-size: 24px;
        margin-inline: 22px;
        line-height: 1.3;
        margin-bottom: 20px;
    }

    .introSection h4 {
        margin-inline: 22px;
        line-height: 1.4;
        margin-bottom: 16px;
    }

    .introSection button {
        font-size: 18px;
        font-weight: bold;
    }

    .mainContent .separatingLine {
        box-shadow: none;
        width: 70%;
        margin: 50px auto 0px auto;
    }

    .stepCard {
        max-width: 254px;
        min-width: 254px;
        margin: auto;
        height: unset;
    }

    .stepCard p {
        line-height: 1.4;
    }

    .stepNumber {
        left: -296px;
    }

    .howItWorksInstructions {
        margin: 0 auto;
    }

    .mainContent .title {
        margin-top: 38px;
    }

    .introduction {
        margin-block: 65px 100px;
    }

    .control {
        margin-block: 140px 100px;
    }

    .descriptionImg {
        max-height: 220px;
        width: 100%;
        max-width: unset;
        object-fit: cover;
        object-position: 0 12%;
        order: -1;
    }

    .introduction .description {
        margin-left: 0;
    }

    .pricingInfo .smallTitle {
        padding-inline: 12px;
    }

    .pricingInfo .card {
        margin-inline: 40px;
        width: unset;
        padding-inline: 22px;
    }

    .pricingInfo .checkList {
        padding-inline: unset;
        font-size: 18px;
        margin-inline: 16px;
    }

    .pricingInfo .checkList p {
        width: 100%;
        text-align: left;
    }

    .pricingInfo .disclaimer {
        font-size: 14px;
        max-width: 250px;
    }

    .missionStatement {
        font-size: 22px;
        margin-block: 100px 30px;
    }

    .control .description {
        margin: unset;
    }

    #mainImage {
        height: 30vh;
        border-radius: 10px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    }

    .gradientCover {
        height: calc(100vh - 72px);
        position: static;
        max-height: 650px;
        background-image: linear-gradient(174deg,
                var(--mainAlpha) -30%,
                rgba(255, 255, 255, 0) 80%)
    }

    p.disclaimer {
        line-height: 20px;
    }
}