@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);

:root {
    --main: #00A6A0;
    --mainAlpha: rgb(0, 166, 160, .93);
    --lighter: #03c9c2;
    --secondary: #1F6BAE;
    --highlight: #F57A75;
    --borderColor: #cfcfcf;
    --mainGrey: #5e5e5e;
    --footerColor: #1F6BAE;
    white-space: pre-wrap;
}

.pricingInfo .tryPlacecardButtonGroup {
    margin-inline: auto;
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

body,
html,
input,
button {
    font-family: 'Ubuntu', sans-serif;
}

th,
h1,
h2,
h3,
h4 {
    font-weight: normal;
}

body {
    text-align: center;
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    min-height: 100vh;
    font-weight: normal;
}

small {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
}

.loginInputs {
    width: 300px !important;
}

.specialAuthenticators li {
    padding: 4px 0px !important;
    border: 3px solid #1F6BAE;
    border: 3px solid var(--secondary);
    border-radius: 4px;
}

.specialAuthenticators li:hover {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    background-color: rgb(245, 245, 245);
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.specialAuthenticators {
    text-align: left;
    width: 300px;
    margin: auto;
    padding: 0px !important;
}

.smallSpacing {
    margin: 2px auto 12px auto!important;
}

.smallLink {
    font-size: 12px !important;
    color: #F57A75;
    color: var(--highlight);
    text-decoration: underline;
    text-align: left;
    width: 300px;
    margin: 4px auto auto auto !important;
}

.smallLink:hover {
    -webkit-filter: brightness(75%);
            filter: brightness(75%);
    cursor: pointer !important;
}

.smallLink:visited {
    color: #1F6BAE;
    color: var(--secondary);
}

.smallLink:active {
    -webkit-filter: brightness(65%);
            filter: brightness(65%);
}

.MuiDialog-paperWidthSm {
    width: 500px;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

button {
    border-radius: 4px;
    border: none;
    /* box-shadow: 2px 2px 6px rgba(0,0,0,0.1); */
    padding: 2px 6px;
    text-transform: none !important;
}

button:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
    /* box-shadow: 4px 4px 8px rgba(0,0,0,0.3); */
}

.title {
    /* letter-spacing: 2px; */
    font-size: 36px;
    margin: 40px auto 6px auto;
    color: #1F6BAE;
    color: var(--secondary);
}

.subtitle {
    font-size: 16px;
    margin: 12px auto 24px auto;
    color: #5e5e5e;
    color: var(--mainGrey);
    max-width: 70%;
    min-width: 400px;
    width: 50%;
    overflow-wrap: normal;
}

.finePrint {
    font-size: 10px !important;
}

.rectangleButton {
    display: block;
    padding: 0px 25px 0px 25px;
    height: 80%;
    align-self: center;
    margin: 5px 20px 5px 15px;
    font-size: 24px;
    letter-spacing: 1px;
    background-color: rgb(225, 225, 225);
    color: black;
    font-style: normal;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    cursor: default;
}

.rectangleButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

.smallerButton {
    width: 20%;
    min-width: 240px;
    margin: 10px auto 50px auto;
    padding: 10px;
}

.verticalContainer {
    display: flex;
    flex-direction: column;
}

.verticalContainer button {
    margin: 2px;
}

.horizontalContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content {
    padding-bottom: 172px;
}


/* input {
  /* box-shadow: 3px 3px 5px rgba(0,0,0,0.2); */


/* border-radius: 2px; */


/* border: 2px solid rgb(228, 228, 228) !important; */


/* } */

label {
    color: #1F6BAE;
    color: var(--secondary);
}

.basicBtn {
    width: 140px;
    margin: 20px auto !important;
    letter-spacing: 1px;
    font-size: 16px !important;
}

.fitBtn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.lowBtn {
    margin-top: 60px !important;
}

.highBtn {
    margin: 8px auto !important;
}

.iconBtn {
    color: #1F6BAE !important;
    color: var(--secondary) !important;
    margin: 0px 8px;
}

.blueBtn {
    background-color: #1F6BAE !important;
    background-color: var(--secondary) !important;
    color: white !important;
}

.smallBtnFnt {
    font-size: 14px !important;
}

.smallMargin {
    margin: 8px auto 0px auto !important;
}

button {
    box-shadow: 2px 3px 3px rgba(73, 73, 73, 0.363) !important;
}

.lowText {
    margin: 50px auto 10px auto;
}

.spacedBottom {
    margin-bottom: 32px !important;
}

.lowTitle {
    padding-top: 24px;
}

.textLink {
    color: #F57A75;
    color: var(--highlight);
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .content>.title,
    .content>section>.title {
        margin-inline: 40px;
        margin-bottom: 38px;
    }
    .content>.subtitle,
    .content>section>.subtitle {
        width: 100%;
        box-sizing: border-box;
        min-width: unset;
        max-width: unset;
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 18px;
    }

    .content {
        padding-bottom: 206px;
    }

    p.subtitle {
        line-height: 26px;
    }

    .title {
        letter-spacing: 0;
    }
}
#mainImage {
    z-index: 0;
    width: 100%;
    object-fit: cover;
    height: 606px;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
}

.pricingInfo .tryPlacecardButtonGroup {
    margin-inline: auto;
}

.gradientCover {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    background-image: linear-gradient(to right,
            var(--main),
            var(--main) 40%,
            rgba(255, 255, 255, 0) 80%);
    z-index: 1;
    position: absolute;
    width: 80%;
    height: 606px;
    -webkit-animation-name: slideLeft;
            animation-name: slideLeft;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
}

.homeTitle {
    color: white;
    letter-spacing: 1px;
    font-size: 48px;
    margin: 0px;
    font-family: 'Arvo', sans-serif;
}

.introSection {
    margin: auto auto auto 10%;
    text-align: left;
    width: 360px;
    display: flex;
    flex-direction: column;
}

.introSection p {
    font-family: 'Lato', sans-serif;
    font-size: 21px;
}

.introduction em {
    font-weight: bold;
    text-decoration: underline;
    font-style: normal;
}

.tryPlacecardButtonGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 300px;
}


.tryPlacecardButtonGroup button {
    font-family: 'Ubuntu', sans-serif;
    font-size: 24px;
    margin-bottom: 14px;
    box-shadow: 10px 10px 0px 0px rgb(65 65 65 / 7%) !important;
}

@-webkit-keyframes slideLeft {
    from {
        left: -1500px;
    }

    to {
        left: 0;
    }
}

@keyframes slideLeft {
    from {
        left: -1500px;
    }

    to {
        left: 0;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}

.howItWorksInstructions {
    display: flex;
    width: 98%;
    max-width: 1500px;
    margin: 30px auto;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: row;
    padding-left: 40px;
}

.stepCard {
    box-shadow: 4px 4px 8px rgb(0 0 0 / 8%);
    border-radius: 7px;
    border: 1px solid rgb(0 0 0 / 8%);
    padding: 20px;
    text-align: left;
    position: relative;
    background: white;
    box-sizing: border-box;
    max-width: 320px;
    min-width: 320px;
    margin: auto;
    height: 250px;
    z-index: 1;
}

.instructionStep {
    position: relative;
    flex: 1 1;
}

.stepNumber {
    font-size: 170px;
    font-weight: bold;
    color: var(--main);
    position: absolute;
    /* moves the numbers under step card */
    left: -367px;
    top: 0;
    bottom: 0;
    right: 0;
    font-family: Arvo, sans-serif;
    font-weight: bold;
}

.separatingLine {
    width: 98%;
    height: 4px;
    border: none;
    background-color: var(--highlight);
    box-shadow: 2px 2px 2px lightgrey;
}

.smallTitle {
    font-size: 32px;
    letter-spacing: 1px;
}

.howToIcons {
    height: 32px;
    width: 32px;
    color: var(--secondary);
    fill: var(--secondary);
}

#uploadIcon {
    stroke-width: 26px;
    stroke: var(--secondary);
}


.stepCardTitle {
    color: var(--secondary);
    margin: 10px 10px;
}

.pricingText {
    text-align: left !important;
    max-width: 60vw;
    margin-right: auto !important;
    margin-left: auto !important;
}

.pricingImg {
    max-width: 25vw;
}

#pricingTitle {
    font-size: 32px;
    color: var(--secondary);
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}

.signupBtn {
    font-size: 1rem !important;
}



.pricingBtn {
    margin-top: 10px !important;
    display: block !important;
    font-size: 75% !important;
}

.headingGroup {
    display: flex;
    align-items: center;
}



.introduction,
.control {
    display: flex;
    flex-direction: row;
    margin-block: 140px;
}

.introduction .description {
    margin-left: 60px;
}

.control .description {
    margin-right: 60px;
}

.descriptionImg {
    max-width: 669px;
    border-radius: 8px;
    box-shadow: 10px 10px 0px 0px rgba(207, 207, 207, .3);


}

.price .tryPlacecardButtonGroup {
    margin-inline: auto;
}

.mainContent {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    color: #5E5E5E;
    text-align: left;
}

.mainContent .smallTitle {
    font-family: 'Arvo', sans-serif;
    font-weight: 38px;
    color: var(--main);
}

.mainContent .centeredContent {
    margin-inline: auto;
    max-width: 1200px;
    justify-content: center;
    align-items: center;
    padding-inline: 10%;
    padding-top: 15px;
}

.pricingInfo {
    background: rgba(214, 214, 214, .2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-block: 40px 80px;
}

.pricingInfo .checkList {
    font-size: 24px;
    padding-inline: 143px;
    margin-top: 28px;
}

.pricingInfo .price {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #5E5E5E;
    font-size: 48px;
    margin-block: 0 24px;
}

.pricingInfo .disclaimer {
    font-size: 18px;
    margin-top: 32px;
}


.pricingInfo .checkList section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


.pricingInfo .checkList p {
    margin-block: 8px;
    margin-left: 1ch;
}

.pricingInfo .card {
    background: #fff;
    box-shadow: 10px 10px 0px 0px rgba(207, 207, 207, .3);
    width: 100%;
    max-width: 649px;
    padding-block: 40px;
    border-radius: 8px;
}

.missionStatement {
    font-family: 'Ubuntu', sans-serif;
    font-size: 36px;
    color: #535353;
    text-align: center;
    max-width: 936px;
    margin-inline: auto;
    padding-inline: 60px;
    margin-block: 140px;
}

.missionStatement img {
    max-width: 131px;
}


@media only screen and (max-width: 1200px) {
    .howItWorksInstructions {
        flex-direction: column;
        align-content: space-between;

    }

    .descriptionImg {
        max-width: 50vw;
    }

    .gradientCover {
        background-image: linear-gradient(to bottom,
                var(--mainAlpha) 74%,
                rgba(255, 255, 255, 0) 93%);
        width: 100%;
    }

    .instructionStep {
        margin: 30px auto;
    }

    .introSection {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}


@media only screen and (max-width: 768px) {
    .introSection .tryPlacecardButtonGroup {
        margin-inline: 62px;

    }

    .introSection .tryPlacecardButtonGroup .small {
        color: #a1a1a1;
    }

    .eyeCatcher p {
        font-family: "Arvo", sans-serif;
        font-size: 16px;
        text-align: left;
        margin-inline: 11px;
        margin-top: 32px;
        margin-bottom: 22px;
        color: black;
    }

    .eyeCatcher p,
    .mainContent p,
    .mainContent li {
        line-height: 26px;
    }

    .missionStatement p {
        line-height: 32px;
    }

    .tryPlacecardButtonGroup {
        max-width: unset;
    }

    .mainContent .centeredContent {
        flex-direction: column;
    }

    .introSection {
        text-align: center;
        margin-inline: 18px;
    }

    .homeTitle {
        font-size: 24px;
        margin-inline: 22px;
        line-height: 1.3;
        margin-bottom: 20px;
    }

    .introSection h4 {
        margin-inline: 22px;
        line-height: 1.4;
        margin-bottom: 16px;
    }

    .introSection button {
        font-size: 18px;
        font-weight: bold;
    }

    .mainContent .separatingLine {
        box-shadow: none;
        width: 70%;
        margin: 50px auto 0px auto;
    }

    .stepCard {
        max-width: 254px;
        min-width: 254px;
        margin: auto;
        height: unset;
    }

    .stepCard p {
        line-height: 1.4;
    }

    .stepNumber {
        left: -296px;
    }

    .howItWorksInstructions {
        margin: 0 auto;
    }

    .mainContent .title {
        margin-top: 38px;
    }

    .introduction {
        margin-block: 65px 100px;
    }

    .control {
        margin-block: 140px 100px;
    }

    .descriptionImg {
        max-height: 220px;
        width: 100%;
        max-width: unset;
        object-fit: cover;
        object-position: 0 12%;
        order: -1;
    }

    .introduction .description {
        margin-left: 0;
    }

    .pricingInfo .smallTitle {
        padding-inline: 12px;
    }

    .pricingInfo .card {
        margin-inline: 40px;
        width: unset;
        padding-inline: 22px;
    }

    .pricingInfo .checkList {
        padding-inline: unset;
        font-size: 18px;
        margin-inline: 16px;
    }

    .pricingInfo .checkList p {
        width: 100%;
        text-align: left;
    }

    .pricingInfo .disclaimer {
        font-size: 14px;
        max-width: 250px;
    }

    .missionStatement {
        font-size: 22px;
        margin-block: 100px 30px;
    }

    .control .description {
        margin: unset;
    }

    #mainImage {
        height: 30vh;
        border-radius: 10px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    }

    .gradientCover {
        height: calc(100vh - 72px);
        position: static;
        max-height: 650px;
        background-image: linear-gradient(174deg,
                var(--mainAlpha) -30%,
                rgba(255, 255, 255, 0) 80%)
    }

    p.disclaimer {
        line-height: 20px;
    }
}
.MuiDialog-paper .forgotPasswordLink{
    margin-bottom: 14px;
    display: inline-block;
}

.close {
    margin: 0px;
    border: none !important;
    border-radius: 100% !important;
    margin-right: 15px !important;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    box-shadow: none !important;
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
}

.closeButton {
    width: 100%;
    justify-content: right;
    display: inline-flex;
}

.loginHeader {
    padding-top: 5px !important;
}
.logoImage {
    width: 100%;
    max-width: 300px;
    max-height: 100px;
}

.loginDialogText {
    max-width: 300px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 20px auto 0px auto!important;
    text-align: center;
}
.vertical-form {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.formBox {
    border: 1px solid var(--borderColor);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    padding: 10px 80px;
    border-radius: 4px;
    box-shadow: 2px 2px 2px rgb(202, 202, 202);
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 15px;
}

.noShadow {
    box-shadow: 0;
}

.MuiOutlinedInput-notchedOutline {
    border: 3px solid var(--borderColor);
    border-radius: 7px;
}

.MuiFormControl-root {
    width: 280px;
    margin: 8px auto !important;
}

.formError {
    color: var(--highlight);
    font-size: 10px;
    display: none;
}

.passEye {
    align-self: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.yesEye {
    display: none;
}

.pageError {
    color: var(--highlight);
    display: none;
    margin-top: 0;
}

.shownError {
    color: var(--highlight);
    margin-top: 0;
}

.successMessage {
    color: var(--main);
    text-align: center;
}

.returnToEvents {
    font-size: 90%;
    color: var(--highlight);
    text-decoration: underline;
    display: inline-flex;
    align-items: center;
    margin: auto;
    padding-bottom: 15px;
}

.returnToEvents:hover {
    cursor: pointer;
}

.backArrow {
    font-size: 90% !important;
    color: var(--highlight) !important;
}

.messages {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {

    .content>.vertical-form{
        margin-inline: 12px;
    }

    .formBox{
        padding-left: 40px;
        padding-right: 40px;
    }

}
.header {
    background-color: white;
    border-bottom: 2px solid hsla(0, 0%, 81%, 0.87);
    box-shadow: 0px 10px 0px rgba(207, 207, 207, 0.192);
    margin: 0 !important;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0;
    z-index: 2;
}

.stickTop {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.footer {
    background-color: var(--footerColor);
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 24px 0 8px 0;
    color: #fff;
    font-size: 14px;
}

.footer .footerInfo {
    color: inherit;
    margin: 8px 34px 2px 34px;
    text-decoration: none;
}


.copyright {
    /* margin-top: 20px; */
    color: rgba(255, 253, 253, 0.3)
}

.logo {
    height: 35px;
    align-self: center;
    margin: 0px 0px 0px 10px;
    padding: 0;
}

.clickable:hover {
    cursor: pointer;
}

.socialIcons {
    color: white;
    display: flex;
    justify-content: space-around;
    width: 8%;
    padding-top: 12px;
    margin: auto;
}

#login {
    display: flex;
    flex-direction: row;
}

.loginUserInput {
    display: flex;
    flex-direction: column;
    align-self: center;
}

#username {
    margin-bottom: 3px;
}

input {
    padding: 5px 3px;
    margin: 2px;
    border: none;
}

.profilePic {
    margin: auto 20px auto 0px;
    cursor: pointer;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    color: var(--secondary) !important;
    border: 1px solid var(--main);
    background-color: white !important;
}

.profilePic:hover {
    -webkit-filter: brightness(95%);
            filter: brightness(95%);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

footer {
    position: absolute;
    bottom: 0;
}

.peachLine {
    background-color: var(--highlight);
    border: 0px;
    width: 60%;
    min-width: 400px;
    max-width: 80% !important;
    height: 3px;
    border-radius: 3px;
    margin: 30px auto;
}

.lowImg {
    margin: 10px auto 0px auto;
}

.highTitle {
    margin-top: 0px;
}

.profileBox {
    display: flex;
    align-content: center;
}

.profileAvatarButton {
    padding-top: 0px !important;
}

.profileMenuDropdown {
    padding-right: 8px;
    padding-left: 8px;
}

.greyBtn {
    background-color: #efefef !important;
    color: var(--secondary) !important;
}

.loginBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 260px;
    margin: 0px 10px 0px 0px;
    padding: 0px;
    align-content: center;
}

.contactInfo:hover{
    text-decoration: underline !important;
    -webkit-text-decoration-color: var(--primary) !important;
            text-decoration-color: var(--primary) !important;
}

@media only screen and (max-width: 600px) {
    .logo {
        height: 27px;
        /* For beta look */
        margin-top: 8px;
    }
    .loginBtns {
        width: auto;
        margin-right: 3px;
        box-sizing: border-box;
        height: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .basicBtn {
        width: unset;
    }
    .loginBtns .basicBtn {
        margin: 3px !important;
        font-size: 14px !important;
    }
}

.footer .logo {
    height: 24px;
    margin: 0;
    margin-bottom: 16px;
}
#edit-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    #edit-cards {
        flex-direction: column;
        align-content: space-between;
    }

    .smallCard, .bigCard {
        width: 50% !important;
        min-width: 400px;
    }
}

.dashCardHeader {
    background-color: var(--secondary);
    text-align: left;
    color: white;
}

.bigCard {
    width: 35%;
}

#success {
    color: var(--main) !important;
}

.flexCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.smallCard {
    width: 20%;
}

.dashCard {
    margin: 10px auto 0px auto;
    grid-template-rows: [header] 20% [main] 60% [btn] 20%;
    display: grid;
    height: 350px;
    text-align: left;
}

.summary p {
    text-align: left;
    font-size: 16px;
}

.summary {
    margin: 10px 10px 10px 16px;
    grid-row: main;
    grid-column: c;
}

.editButton {
    display: block;
    padding: 10px 15px 10px 15px;
    margin: auto;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: rgb(225, 225, 225);
    color: black;
    font-style: normal;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.1);
    cursor: default;
    width: 30%;
    min-width: 140px;
    grid-row: btn;
    grid-column: c;
}
  
  .editButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
    box-shadow: 4px 4px 8px rgba(0,0,0,0.3);
  }

  .pieChart {
      width: 120px;
      padding-right: 20px;
  }

  .cardFooter * {
      margin-left: 8px !important;
  }
#edit-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.prevBtn {
    margin-right: 8px !important;
}

.pie {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    #edit-cards {
        flex-direction: column;
        align-content: space-between;
    }

    .smallCard, .bigCard {
        width: 50% !important;
        min-width: 400px;
    }
}

.dashCardHeader {
    background-color: var(--secondary);
    text-align: left;
    color: white;
}

.bigCard {
    width: 35%;
}

.flexCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.smallCard {
    width: 20%;
}

.dashCard {
    margin: 10px auto 0px auto;
    grid-template-rows: [header] 20% [main] 60% [btn] 20%;
    display: grid;
    height: 350px;
    text-align: left;
}

.summary p {
    text-align: left;
    font-size: 16px;
}

.summary {
    margin: 10px 10px 10px 16px;
    grid-row: main;
    grid-column: c;
}

.editButton {
    display: block;
    padding: 10px 15px 10px 15px;
    margin: auto;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: rgb(225, 225, 225);
    color: black;
    font-style: normal;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.1);
    cursor: default;
    width: 30%;
    min-width: 140px;
    grid-row: btn;
    grid-column: c;
}

  .editButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
    box-shadow: 4px 4px 8px rgba(0,0,0,0.3);
  }

  .pieChart {
      width: 120px;
      padding-right: 20px;
  }

  .cardFooter * {
      margin-left: 8px !important;
  }

@media only screen and (max-width: 768px) {
    .dashCard{
        min-width: 300px;
    }

    /* Start survey */
    #edit-cards #surveyPreview .innerBoxHeader .MuiCardHeader-title {
        font-size: 22px;
    }

    #edit-cards #surveyPreview .title {
        font-size: 28px;
    }

    #edit-cards #surveyPreview .survey {
        min-width: unset;
        width: 100%;

    }

    #edit-cards #surveyPreview .MuiCardContent-root {
        padding: 12px;
        height: calc(100vh - 140px);
        overflow-y: auto;
        box-sizing: border-box;
    }

    #edit-cards #surveyPreview>.MuiPaper-root {
        margin: 0 !important;
        position: fixed;
        top: 0;
    }

    #edit-cards #surveyPreview .MuiDataGrid-columnHeaderWrapper{
        padding: 0px;
        width: 100%;
    }

    #edit-cards #surveyPreview .MuiDataGrid-root {
        border: 0 !important
    }

    #edit-cards #surveyPreview .survey .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
        padding-left: 26px !important;
    }

    #edit-cards #surveyPreview .generalButton {
        margin: 60px 12px 22px 12px !important;
    }

    #edit-cards #surveyPreview .smallSearch {
        width: 100%;
    }
}

.boxes {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 90%;
}

#addEventBtn {
    margin: 20px auto 20px auto;
    padding: 10px 0px 10px 0px;
    width: 90%;
}

.table {
    margin: 30px auto;
    width: 750px;
    border: none;
    box-shadow: 4px 4px 4px lightgrey;
    border-radius: 7px;
    box-sizing: border-box;
}

.MuiDataGrid-root {
    border-radius: 7px !important;
    border: 0.5px solid rgb(241, 241, 241) !important;
}

.MuiDataGrid-main {
    border: none;
}

.MuiDataGrid-columnHeaderWrapper {
    padding-left: 12px;
    background-color: var(--secondary);
    color: white;
    font-weight: bold;
    font-size: 16px;
    border-radius: 7px 7px 0px 0px;
    padding-right: 12px;
}

.MuiDataGrid-cell {
    border: none;
}

.MuiDataGrid-columnsContainer {
    border: none;
    border-radius: 7px 7px 0px 0px;
}

.MuiDataGrid-columnSeparator {
    opacity: 0 !important;
}

.MuiDataGrid-cell {
    border: none !important;
}

.MuiDataGrid-row {
    border: none;
}

.MuiDataGrid-cell--textLeft {
    border: none;
}

.userDashContent .table .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    padding-left: 24px;
}

.dashBtn {
    font-size: 14px !important;
}

.plusBtn {
    color: var(--lighter);
    position: relative;
    left: -8px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: white;
}

.leftBtn {
    justify-self: flex-start;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.userDashContent {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto auto 40px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.userDashFooter {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.userDashText {
    color: var(--lighter);
}

.userDashContent button.MuiIconButton-root {
    box-shadow: none !important;
}

@media only screen and (max-width: 768px) {
    .userDashContent{
        width: 100%;
    }

    .userDashContent button.MuiButton-root{
        width: 100%;
        max-width: 200px;
        margin-inline: auto;
    }


    .userDashContent .table {
        margin: 10px 4px 18px 4px;
        width: calc(100% - 8px);
        /* padding-bottom: 20px; */
    }

    .userDashContent .table .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent{
        height: 100% !important;
        overflow-y: auto;
    }

    .userDashContent .MuiDataGrid-main .MuiDataGrid-row{
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .userDashContent .MuiDataGrid-main .MuiDataGrid-row>.MuiDataGrid-cell:first-child {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .userDashContent .MuiDataGrid-main .MuiDataGrid-row>.MuiDataGrid-cell:first-child>p {
        margin: 0;
        line-height: normal;
    }

    .userDashContent .MuiDataGrid-main .MuiDataGrid-row>.MuiDataGrid-cell:last-child {
        padding-left: 6px;
    }

    .userDashContent .MuiDataGrid-cell .cellSubtitle{
        padding-left: 8px;
        font-style: italic;
        color: #aaa;
    }

    .userDashContent .MuiDataGrid-cell .dateSubtitle{
        font-size: 10px;
    }

    .userDashContent .MuiDataGrid-cell .countdownSubtitle{
        font-size: 10px;
    }

    .userDashFooter{
        flex-direction: column-reverse;
        align-items: center;
    }

    .userDashFooter .MuiButton-root{
        margin: 16px;
    }
}
.subgroupMember {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 8px;;
}

.subgroupMember .MuiFormControl-root {
    max-width: 300px;
    margin: 0 !important;
}

.guestListTable .addButtonGroup{
	text-align: left;
}

.guestListTable .addButtonGroup button {
    width: 190px;
}

.guestListTable .nameCol,
.guestListTable .contactCol {
    width: 25%;
    padding-left: 15px;
    height: 56px;
}

.guestListTable .nameCol .MuiFormControl-root,
.guestListTable .contactCol .MuiFormControl-root{
	margin-left: -14px !important;
}

.guestListTable .firstColName,
.guestListTable .firstColContact{
	padding-left: 10px;
}

.guestListTable {
	max-width: 1000px;
	text-align: left;
	margin: 20px auto;
	border-radius: 8px;
	border: 2px solid var(--secondary);
	background-color: var(--secondary);
}

.resultTable {
	border-collapse: collapse;
	width: 100%;
	border-radius: 6px;
	overflow: hidden;
	border: none;
	background-color: white;
}


.subgroupMemberList {
	list-style: none;
	padding-inline: 38px 12px;
}

.guestListIcon {
	box-shadow: none !important;
}

.headingRow {
	background-color: var(--secondary);
	padding-right: 5px;
}

thead {
	color: white;
   	 height: 50px;
}

.firstCol {
	padding-left: 15px;
}

.lastCol{
	padding-right: 15px;
}

.midCol{
	padding-right: 15px;
}

tbody .groupSummary td {
	padding-top: 12px;
}

tbody tr:last-child.groupDropdown td {
    	padding-bottom: 12px;
}

.saveCheck {
	max-width: 1.25ch;
	opacity: 50%;
	padding-left: 3px;
}

.errorCheck {
	max-width: 1.25ch;
	opacity: 50%;
	padding-left: 3px;
	color: red;
}



/* tbody tr {
	border-left: 3px solid var(--secondary);
	border-right: 2px solid var(--secondary);
} */


@media only screen and (max-width: 768px) {
	.resultTable{
		table-layout: fixed;
		width: 100%;
		overflow-wrap: break-word;
	}

	.guestListTable .nameCol,
	.guestListTable .contactCol {
		max-width: 30%;
		width: unset;
	}

	.firstCol{
		padding-left: 0px;
		width: 5%;
	}

	.guestListTable .firstColName{
		padding-left: 0%;
		width: 50%;
		padding-right: 14px;
	}

	.guestListTable .sendSurveyColumnHeader{
		width: 12%;
		font-size: 12px;
	}

	.guestListTable .editColumnHeader{
		width: 10%;
		font-size: 12px;
	}
	.guestListTable .deleteColumnHeader{
		width: 14%;
		font-size: 12px;
	}

	.guestListTable .guestContact{
		color: rgb(133, 133, 133);
		margin-top: 4px;
		margin-bottom: 4px;
		margin-left: 8px;
		text-overflow: ellipsis;
		overflow: hidden;
		overflow-wrap: normal;
	}

	.guestListTable .groupSummary .firstCol .guestListIcon{
		padding-left: 3px;
	}

	.subtitle{
		min-width: 300px;
	}

	.guestListTable .midCol {
		padding: 2px;
		font-size: 12px;
	}

	.guestListTable .statusColumn {
		font-size: 12px;
	}
}


#guestListFileUpload {
	display: none;
}

#inputTestTag {
	font-size: 18px;
	display: block;
	width: 100%;
	border: none;
	text-transform: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	margin: 0;
	padding: 0;
}

#dragDropContainer{
	position: relative;
	margin: 0 0 15px;
	border: 2px dotted lightgray;
	padding: 35px 20px;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: transparent;
	min-width: 400px;
	width: 800px;
	height: 200px;
}

.uploadFileIcon {
	width: 88px;
	height: 88px;
	align-self: center;
}

.fileUploadButtonSection {
	position: relative;
	top: 92px;
	display: grid;
	grid-template-columns: [first] 20% [second] 80%;
	grid-column-gap: 54px;
	-webkit-column-gap: 54px;
	        column-gap: 54px;
	height: 0;
}

.fileUploadButtonSection p{
	font-size: 22px;
}

.fileUploadSection {
	display: flex;
	flex-direction: column;
	max-width: 1000px;
	margin: auto;
	justify-content: center;
	align-items: center;
}

.horizontalOr {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 20px;
    grid-gap: 20px;
    gap: 20px;
    max-width: 1200px;
    align-self: center;
    padding: 0 80px;
    margin: 40px auto 74px auto;
}

.horizontalBar {
    height: 3px;
    background: #CFCFCF;
    width: 100%;
}

.horizontalBar span{
	font-size: 20px;
}

.manualGuestListSection{
	max-width: 1200px;
	margin: auto;
}

.addGuestRowBottomControl{
	text-align: left;
}

.buttonControlGroup{
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 900px;
	margin: 60px auto 0 auto;
}

.buttonControlGroup > * {
    margin-inline: 2px;
}

.addButton {
	flex: 1 1;
	padding: 15px;
	font-size: 18px;
}

#addIndividualButton{
	border-radius: 6px;
}

#addCoupleOrGroupButton{
	border-radius: 6px;
}

.buttonPopOut {
	max-width: 900px;
	background: white;
	margin: 12px auto;
	padding: 10px 18px 22px 18px;
	box-sizing: border-box;
	display: grid;
	outline: 2px solid #cfcfcf;
	border-radius: 8px;
	grid-row-gap: 3px;
	row-gap: 3px;
   	grid-column-gap: 12px;
   	-webkit-column-gap: 12px;
   	        column-gap: 12px;
}

.individualPopOut{
	grid-template-columns: [first] auto [second] auto [third] auto [fourth] 165px;
}

.groupPopOutHeader {
    background: var(--secondary);
    color: white;
    text-align: left;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 17px;
    font-weight: bold;
}

.groupPopOut.buttonPopOut{
	grid-template-columns: [first] 60% [second] auto;
	padding-top: 18px;
	grid-row-gap:0px;
	row-gap:0px;
	grid-column-gap: 0px;
	-webkit-column-gap: 0px;
	        column-gap: 0px;
	padding:0;
}

.groupInformationHeader{
	border-radius: 8px 0px 0px 0px;
    padding-left: 20px;
}

.groupMembersHeader{
	border-radius: 0px 8px 0px 0px;
    padding-left: 40px;
}

.sendSurveyInputSection{
	margin-top: auto;
	margin-bottom: auto;
}

.MuiFormControl-root{
	width: 100%;
}

.plusOneInputGroup{
	display: flex;
}

.plusOneInput{
	flex:1 1;
}

.compactNumberComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
	margin-top: -4px;
	margin-inline:auto;
}

.numberComponentAction {
    display: flex;
    flex-direction: row;
    background: #f1f1f1;
    height: 40px;
    border-radius: 6px;
    border: 2px solid #d9d9d9;
    position: relative;
	margin-left: 18px;
}

.compactNumberComponent .MuiFormControl-root {
    width: 48px;
    margin-top: 0px !important;
    position: absolute;
    left: 50%;
    margin-left: -24px !important;
    z-index: 1;
}

.compactNumberComponent .MuiFormControl-root .MuiInputBase-root{
	background: white;
}

.compactNumberComponent .MuiInputBase-input{
	text-align: center;
}

.plusButton{
	padding-left: 30px;
}

.minusButton{
	padding-right: 30px;
}

.compactNumberComponent .numberComponentActionButton {
	width: 64px;
    height: 40px;
    box-shadow: unset !important;
}

.compactNumberComponent .MuiFormHelperText-root {
    position: absolute;
    width: 221px;
    top: 44px;
    left: -91px;
}


.addButtonGroup {
	grid-column: span 4;
	text-align: right;
}
.addButtonGroup button {
    width: 130px;
}

.addButton{
	color:#5E5E5E;
	background-color: #CFCFCF;
}

.addButton.activeButton{
	background-color: var(--secondary);
	color: white;
}


.unknownNameCheckboxGroup{
	display: flex;
	align-items: center;

}

.groupInfo {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 89px 80px;
	grid-gap: 20px;
	grid-gap: 20px;
	gap: 20px;
	padding-right: 10px;
	grid-column: 1;
	padding-left: 18px;
    padding-top: 21px;
}

.groupMembers{
	position: relative;
    padding-left: 40px;
        padding-left: 40px;
    padding-right: 18px;
    padding-top: 10px;
}

.groupPopOut .addButtonGroup{
    padding-bottom: 26px;
    padding-right: 18px;
    padding-top: 4px;
}


.groupMembers:before {
    content: " ";
    border-left: 1px solid #CFCFCF;
    height: 100%;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    left: 20px;
}

.downloadTemplate {
    color: var(--main);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: right;
    max-width: 780px;
    margin: 5px auto 20px auto;
    width: 100%;
}

.downloadTemplate * {
	align-self: center;
}

.backendError {
    color: var(--highlight);
}

.errorText {
	color: var(--highlight);
    font-size: 10px !important;
	padding: 4px 8px;
	margin-bottom: 0px !important;
}

.success {
	color: var(--highlight);
	margin-bottom: 0px;
	padding-bottom: 0px;
}

@media only screen and (max-width: 768px) {
	.fileUploadSection{
		max-width: 100%;
		padding-left: 4px;
		padding-right: 4px;
	}

	.downloadTemplate{
		width: unset;
	}

	.uploadFileIcon {
		width: 42px;
		height: 42px;
		margin-left: auto;
	}

	.fileUploadButtonSection{
		grid-column-gap: none;
		grid-column-gap: normal;
		-webkit-column-gap: normal;
		        column-gap: normal;
		top:5px;
	}

	.fileUploadButtonSection>section{
		max-width: 80%;
		line-height: 1.5;
	}

	#dragDropContainer{
		margin-inline: 55px;
		width: 100%;
		box-sizing: border-box;
		height: 172px;
	}

	.manualGuestListSection .addButton {
		margin: 4px;
		width: 100%;
		max-width: 190px;
		line-height: 1.5;
		padding-left: 6px;
		font-size: 14px;
		padding-right: 6px;
	}

	.buttonPopOut{
		display: flex;
    	flex-direction: column;
		margin-inline: 20px;
	}

	.groupPopOutHeader{
		display: none;
	}

	.groupInfo{
		display: flex;
		flex-direction: column;
		padding-top: 9px;
		grid-gap: 12px;
		gap: 12px;
	}

	.groupMembers{
		padding-left: 18px;
	}

	.groupMembers>p{
		margin-top:58px;
	}

	.groupMembers:before{
		border-top: 1px solid #CFCFCF;
		height: 100%;
		position: absolute;
		top: 32px;
		width: 100%;
		left: 0px;
		border-left: none;
	}

	.manualGuestListSection{
		padding-left: 4px;
		padding-right: 4px;
	}
}
.box {
    border: 1px solid var(--borderColor);
    display: flex;
    flex-direction: column;
    text-align: left !important;
    width: 600px;
    min-height: 320px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: center;
    font-size: 14px;
    padding: 12px 12px 12px 0px;
    margin: 12px auto 24px auto !important;
    box-shadow: 4px 4px 2px rgba(150, 150, 150, 0.315) !important;
    overflow: scroll;
}

.betaHeader {
    color: var(--highlight);
}

.box * {
    padding-bottom: 6px;
    padding-top: 12px;
    padding-left: 24px;
    margin: 0;
    color: var(--mainGrey);
}

.box textarea {
    border: 1px solid var(--borderColor);
    padding-left: 12px;
    min-height: 40px;
    margin: 12px 80px 16px 80px;
    font-family: inherit;
    resize: vertical;
    border-radius: 4px;
}

.box textarea:focus,
.box textarea:focus-visible {
    border: 2px solid var(--highlight) !important;
    outline: none;
}

.centered {
    display: flex;
    flex-direction: column;
}

.details {
    padding: 1px 1px 1px 16px;
    margin: auto 80px 2px 64px;
}

.customInput {
    margin-left: 16px;
    margin-right: 80px;
    padding-bottom: 40px;
}

.signature {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
}

.horizontalFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.verticalFlex {
    margin-right: 50px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    text-align: center;
}

.verticalFlex a {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 16px;
}

.innerBox {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 20px auto;
    min-width: 400px;
    min-height: 200px;
    margin: auto;
    margin-top: 150px;
    background-color: white;
}

.innerBoxHeader {
    background-color: var(--secondary);
    color: white;
}

.innerBoxContent {
    padding: 16px 24px !important;
}

.spacedBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.spacedBtns * {
    margin: 4px auto 8px auto !important;
}

.biggerBox {
    height: 60%;
    min-height: 480px;
}

.hiddenBoxes {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    z-index: 5;
}

.smallButton {
    margin: 40px 30px 0px 30px;
    padding: 10px 25px 10px 25px;
    font-size: 20px;
}

.smallBoxTitle {
    padding-top: 20px;
    padding-bottom: 20px;
}

.profilePage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 24px;
}

.profileElem {
    font-size: 18px;
    padding: 8px 8px 12px 8px;
    font-weight: bold;
}

#sentNotification {
    display: none;
}

.hiddenNote {
    display: none;
    color: var(--lighter);
}

@-webkit-keyframes disappear {
    from {
        opacity: 100;
        height: 40px;
    }
    75% {
        opacity: 100;
        height: 40px;
    }
    99% {
        height: 40px;
    }
    to {
        opacity: 0;
        visibility: hidden;
        height: 0;
    }
}

@keyframes disappear {
    from {
        opacity: 100;
        height: 40px;
    }
    75% {
        opacity: 100;
        height: 40px;
    }
    99% {
        height: 40px;
    }
    to {
        opacity: 0;
        visibility: hidden;
        height: 0;
    }
}

.disappearingClass {
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-name: disappear;
            animation-name: disappear;
    opacity: 0;
    height: 0;
}

.bottomButtons {
    margin: 0px 4px 0px 4px;
}

.profileData {
    font-weight: normal;
    padding-left: 10px;
    font-size: 16px;
}

.editable {
    display: none;
    border: 1px solid black;
    margin: 0px 8px 0px 8px;
}

.homeButton {
    width: 100px;
    margin: 24px auto 24px auto !important;
    font-size: 24px !important;
}

#editProfileError {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.editProfError {
    color: red;
    font-size: 12px;
    margin-left: 6px;
    display: none;
}

.picButton {
    margin: 0px 620px 12px 0px !important;
    padding-top: 0 !important;
}

#editProfPic {
    display: none;
}

#fileChooser {
    background-color: rgba(228, 228, 228, 0.555);
    border: 0.3px solid black;
    text-align: center;
    padding: 5px !important;
    margin-left: 10px !important;
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
}

#fileChooser:hover {
    background-color: rgba(228, 228, 228, 0.888);
    cursor: pointer;
}

.closeHorizontalFlex {
    display: flex;
    justify-content: flex-start;
}

.passInputs input {
    border: 1px solid black;
    border-radius: 4px;
}

.passInputs label {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 55%;
    min-width: 180px;
    margin: auto auto 10px auto;
}

.passInputs {
    margin-bottom: 25px;
}

.passChangeFooter {
    margin: auto 24px auto 24px;
}

.closeBox {
    display: flex !important;
    margin: 5px!important;
}

.closeButtons {
    margin: auto;
    width: 600px;
}

.surveyBtns {
    width: 100%;
    margin: auto;
    max-width: 1000px;
}

.surveyPreview {
    width: 900px;
    margin: 76px auto !important;
}

.previewFooter {
    background-color: var(--borderColor);
    height: 60px;
    display: flex;
}

.previewFooter * {
    margin: auto !important;
}

.closeBtn {
    color: white;
}

.editSurvey .surveyBtns .horizontalFlex .MuiButton-root {
    flex: 1 1;
    max-width: 235px;
    margin: 20px !important;
}

.editSurvey .surveyBtns .horizontalFlex.pageNavBtns .MuiButton-root {
    max-width: 164px;
}


@media only screen and (max-width: 768px) {
    .verticalFlex {
        margin-inline: 0;
    }
    .box {
        max-width: 90%;
    }
    .box>* {
        margin-inline: 12px;
        line-height: 1.8;
    }
    .box textarea {
        margin-inline: 28px;
    }
    .horizontalFlex:first-child {
        flex-direction: column;
        align-items: center;
    }

    .editSurvey .surveyBtns .horizontalFlex .MuiButton-root{
        width: 100%;
    }
    .surveyBtns {
        width: 100%;
    }
    .surveyBtns .basicBtn {
        margin: 12px !important;
    }
    .surveyPreview {
        max-width: 900px;
        width: 100%;
    }
    .closeButtons {
        width: 100%;
    }

    .editSurvey .surveyBtns .horizontalFlex button.MuiButton-root{
        margin: 6px 4px !important;
    }
}
.seatStat {
    color: rgb(75, 75, 75);
    font-size: 28px;
    margin: 0px;
}

.addTableBtn {
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 20px !important;
    padding: 12px 22px !important;
}

.generateBtn {
    margin-left: auto !important;
    font-size: 20px !important;
    margin-right: 10px !important;
}

.fakeTableSection {
    align-items: center;
    justify-items: center;
    display: flex;
    width: 100%;
    height: 100%;
}

.fakeTableBox {
    margin: 6px;
    border: none;
    box-shadow: none !important;
    height: 100px;
}

.statLabel {
    margin: 0px 0px 12px 0px;
    color: rgb(75, 75, 75);
}

.titleBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pageHeader {
    margin: 20px 24px auto 24px;
}

.titleBar h1 {
    font-size: 32px;
    margin: 0;
}


/* Hide scrollbar for Chrome, Safari and Opera */
/*
.guestTable::-webkit-scrollbar {
    display: none;
} */

.guestTable {
    min-height: 500px;
    max-height: 700px;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    /* scrollbar-width: none; */
    /* Firefox */
}

.guestTable button {
    box-shadow: none;
    color: white;
    margin-left: 10px;
}

.tableHeader {
    background-color: white !important;
    color: var(--main) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.smallHR {
    width: 80%;
    padding: 0;
    margin: 0 auto;
}

.cornerText {
    font-size: 10px;
    float: left;
    color: var(--mainGrey);
}

.tableBox {
    margin: 6px;
    border: 1px solid var(--borderColor);
}

.scrollable {
    overflow: scroll;
    margin-top: 0px !important;
}

/* .seatingChart::-webkit-scrollbar {
    display: none;
} */

.seatingChart {
    display: flex;
    flex-direction: column;
    max-height: 700px;
    -ms-overflow-style: none;
    /* IE and Edge */
    /* scrollbar-width: none; */
    /* Firefox */
}

/* .tableBody {
    max-width: 90%;
    padding: 5px;
} */

.seat {
    list-style: none;
    text-align: left;
    list-style-type: none;
    border-bottom: 1px solid black;
    padding: 3px 8px 1px 12px;
    display: flex;
    justify-content: space-between;
}

ul {
    margin: 0px;
    padding: 0px;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.centeredCell {
    text-align: center !important;
    justify-content: center !important;
}

.nameCell {
    margin-left: 7px;
}

.centeredContent {
    justify-content: center;
}

.tableInSeatDash {
    min-height: 200px;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.unseatedSection {
    min-height: 500px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.overBackground {
    background-color: rgb(245, 245, 245);
}

.guestName {
    padding: 4px;
    background-color: white;
    color: var(--footerColor);
    border: 1px solid var(--borderColor);
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    margin: 0 20px 15px 20px;
}

.guestName:last-child {
    margin-bottom: 0;
}

.placedGuest {
    box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
    -webkit-filter: brightness(100%);
            filter: brightness(100%);
    -webkit-animation-duration: 0.25s;
            animation-duration: 0.25s;
    -webkit-animation-name: putDown;
            animation-name: putDown;
}

.draggingGuest {
    -webkit-filter: brightness(85%);
            filter: brightness(85%);
    box-shadow: 7px 7px 20px rgb(0 0 0 / 15%);
    -webkit-animation-duration: 0.25s;
            animation-duration: 0.25s;
    -webkit-animation-name: pickUp;
            animation-name: pickUp;
}

@-webkit-keyframes pickUp {
    from {
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
        box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
    }
    to {
        -webkit-filter: brightness(85%);
                filter: brightness(85%);
        box-shadow: 7px 7px 20px rgb(0 0 0 / 15%);
    }
}

@keyframes pickUp {
    from {
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
        box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
    }
    to {
        -webkit-filter: brightness(85%);
                filter: brightness(85%);
        box-shadow: 7px 7px 20px rgb(0 0 0 / 15%);
    }
}

@-webkit-keyframes putDown {
    from {
        -webkit-filter: brightness(85%);
                filter: brightness(85%);
        box-shadow: 7px 7px 20px rgb(0 0 0 / 15%);
    }
    to {
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
        box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
    }
}

@keyframes putDown {
    from {
        -webkit-filter: brightness(85%);
                filter: brightness(85%);
        box-shadow: 7px 7px 20px rgb(0 0 0 / 15%);
    }
    to {
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
        box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
    }
}

.overFull {
    color: red !important;
    background-color: var(--borderColor);
    font-weight: bold;
    font-size: 18px;
}

.tableFillLevel {
    background-color: var(--borderColor);
    font-size: 16px;
    color: black !important;
}

.topTableHeader {
    margin-bottom: 0 !important;
    text-align: center;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    display: flex !important;
    justify-content: space-between;
}

.topTableHeader * {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    text-align: center;
    padding: 6px !important;
}

.tableOptions {
    display: flex !important;
    justify-content: space-between;
    align-content: center;
    margin: 0px 30px;
}

.clearButton {
    height: 30px !important;
    color: black !important;
    background-color: white !important;
    align-self: center !important;
}

.seatingChartHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.noShadow {
    box-shadow: none !important;
}

.whiteTxtBtn {
    color: white !important;
    font-size: 14px !important;
}

.secondaryBtn {
    background-color: var(--highlight) !important;
    margin-right: 4px !important;
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.greyTxtBtn {
    color: var(--mainGrey) !important;
    font-size: 14px !important;
    box-shadow: none !important;
    border: none !important;
}

.fullLengthSection {
    width: 80%;
    max-width: 1200px;
    min-width: 800px;
    margin: auto;
}

.tableName {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

.firstCard {
    padding-top: 20px;
}

.topCard {
    margin: 48px auto;
    box-shadow: 7px 7px 4px rgba(172, 171, 171, 0.384) !important;
}

.tableTitle {
    background-color: var(--secondary);
    color: white;
    font-size: 22px;
}

.cardHeader {
    background-color: var(--secondary);
    text-align: left;
    color: white;
    padding: 8px 12px !important;
    font-size: 22px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

@media only screen and (max-width: 1024px) {
    .seatingChart .cardHeader div:last-child {
        display: flex;
    }
}

@media only screen and (min-width: 1024px) {
    .seatingChart .cardHeader div:last-child {
        display: flex;
        align-items: center;
    }
    .switchLabel {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
    }
}

.seatingChart .cardHeader div:last-child {
    /* width: 100%; */
    margin-left: 12px;
    margin-top: 8px;
}

.fullCardHeader {
    width: 100%;
}

.cardHeader * {
    font-size: 22px;
}

.seatDashCard {
    box-shadow: 4px 4px 4px rgba(172, 171, 171, 0.384);
    border: 1px solid var(--borderColor);
}

.returnDashBtn {
    color: white !important;
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
}

.downloadBtn {
    padding-right: 10px !important;
}

.searchBar {
    width: 85%;
}

.stickySearch {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 3px 3px rgba(167, 165, 165, 0.253);
    height: 64px;
    width: 100%;
}

.wrappedP {
    overflow: wrap;
    padding: 2px;
}

.switchLabel {
    color: white;
    font-size: 10px;
    display: inline-flex;
    flex-direction: column;
    margin-right: 8px;
}


.infoError {
    color: var(--highlight) !important;
    float: right;
    width: 14px;
    height: 14px;
    padding: 0 !important;
    cursor: help !important;
}

.editTableNum {
    color: var(--secondary) !important;
    width: 18px;
    height: 18px;
    padding: 0 !important;
}

.editTableNum:disabled {
    color: var(--borderColor) !important;
}

.spacedSpan {
    padding: 0px 6px;
}

.smallClose {
    color: black !important;
    padding: 0 !important;
    width: 24px;
    box-shadow: none !important;
    height: 24px;
}

#sortMenu {
    display: flex;
    justify-content: center;
    padding: 3% 0 0 0;
    grid-gap: 0.5ch;
    gap: 0.5ch;
}

.seatDashCard button {
    box-shadow: none !important;
}

@media only screen and (max-width: 768px) {
    .fullLengthSection{
        min-width: unset;
        width: auto;
    max-width: calc(100% - 10px);
    margin-bottom: -300px;
    }

    .fullLengthSection h1.title{
        font-size: 28px;
        margin: 25px auto 0px auto;
    }

    .topCard{
        margin-top: 20px;
    }

    .fullLengthSection .MuiGrid-root.dashBody>.MuiGrid-root {
        padding-left: 0px;
    }

    .fullLengthSection .MuiGrid-root.dashBody>.MuiGrid-root.setDashCardContainer {
        position: absolute;
        top: 100%;
        z-index: 6;
        width: 100%;
        padding-top: 28px;
        height: 29vh;
    }

    .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer {
        width: 100%;
        margin-inline: auto;
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        padding-top: 16px;
        height: 100%;
    }


    .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer .seatDashCard {
        height: 100%;
    }

    .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer .seatDashCard .guestTable.centeredCell{
        min-height: unset;
        max-height: calc(100% - 42px);
    }

    .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer .seatDashCard .guestTable.centeredCell .unseatedSection{
        min-height: unset;
        display: flex;
        flex-wrap: wrap;
    }

    .unseatedSection .guestName{
        width: 100%;
        max-width: calc(50% - 30px);
        box-sizing: border-box;
        margin: 0 15px 15px 15px
    }

    .unseatedSection .guestName:last-child{
        margin-bottom: 15px;
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .cardHeader .MuiCardHeader-content .MuiTypography-root {
        white-space: nowrap;
        font-size: 20px;
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .cardHeader .MuiCardHeader-action .switchLabel{
        display: none
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .cardHeader .MuiCardHeader-action .pipeSeparator{
        display: none;
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .cardHeader .MuiCardHeader-action button {
        padding: 3px 6px;
        min-width: unset;
        font-size: 11px !important;
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .cardHeader .MuiCardHeader-action  {
        margin: 0;
        display: flex;
        align-self: center;
    }

    .seatingChart.seatDashCard>.MuiGrid-container{
        height: 60vh;
        flex-direction: column;
        overflow-x: scroll;
    }

    .seatDashCard .MuiGrid-root.MuiGrid-container>.MuiGrid-item {
        flex-basis: 50%;
        max-height: 50%;
    }

    .seatDashCard .MuiGrid-root.MuiGrid-container.firstCard>.MuiGrid-item {
        margin-inline: auto;
    }

    .seatDashCard .MuiGrid-root.MuiGrid-container.firstCard>.MuiGrid-item:last-child {
        grid-column: 1 / span 2;
        padding-block: 4px 30px;
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .tableBox {
        height: calc(100% - 12px);
        box-sizing: border-box;
    }


    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .tableBox .tableInSeatDash {
        min-height: unset;
        height: calc(100% - 60px - 8px);
        box-sizing: border-box;
        overflow: scroll;
    }

    .fullLengthSection .dashBody .tableBox .tableHeader .topTableHeader {
        min-height: unset;
    }

    .fullLengthSection .dashBody .tableBox .tableHeader .topTableHeader .tableName{
        padding: 0px !important;
        padding-left: 4px !important;
    }


        .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer .seatDashCard .guestTable.centeredCell .stickySearch{
            position: absolute !important;
            width: 50%;
            top: 0;
            right: 0;
            margin-top: 36px;
            margin-right: 4px;
            height: unset;
            border-radius: 4px;
        }


        .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer .seatDashCard .guestTable.centeredCell .searchBar{
            background: white;
            border-radius: 4px;
            width: 100%;
            margin: 0!important;
        }

        .fullLengthSection #searchBar{
            padding: 2px 14px
        }

        .firstCard .MuiCardHeader-title{
            font-size: 1.2rem;
        }


        .fullLengthSection .dashBody.firstCard {
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding-top: 0px;
            padding-bottom: 10px;
            display: grid;
            grid-template-columns: 50% 50%;
        }
        .fullLengthSection .dashBody.firstCard>div {
            max-width: 85%;
        }

        .fullLengthSection .topCard .cardHeader {
            flex-direction: column-reverse;
        }

        .fullLengthSection .topCard .cardHeader .MuiCardHeader-action {
            align-self: center;
        }

        .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .tableBox .tableFillLevel {
            padding: 4px;
            font-size: 12px;
        }
    /* .fullLengthSection>.MuiGrid-root.MuiGrid-container{
        height: 100vh;
    } */

    /* .fullLengthSection>.MuiGrid-root.MuiGrid-container.dashBody>.MuiGrid-item:first-child{
        position: fixed;
        z-index: 9;
        height: 33%;
        box-sizing: border-box;
        bottom: 0;
        left: 0;
    } */
}
.tinyButton {
    padding: 8px 16px 8px 16px;
    margin: 20px 100px 10px 100px;
}

.smallSearch {
    width: 550px;
    margin: auto;
    display: flex;
    justify-content: left;
    box-shadow: none;
    padding: none;
}

.lowSub {
    margin-top: 70px;
    padding: 0px 70px 0px 70px;
}

.lowButtons {
    margin: 200px 70px 0px 70px;
}

.smallP {
    font-size: 14px;
    padding: 2px;
}

.guestConfirmation {
    max-width: 1000px;
    margin: auto;
}

.textInput {
    margin-top: 10px !important;
}

.survey {
    margin: 30px auto;
    border: none;
    box-shadow: 4px 4px 4px lightgrey;
    border-radius: 7px;
    margin: auto !important;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    width: 40%;
    min-width: 500px;
    /* border: 1px solid grey; */
    /* border-radius: 5px; */
}


/* Hide scrollbar for Chrome, Safari and Opera */

.survey::-webkit-scrollbar {
    display: none;
}

.MuiDataGrid-row {
    margin: 0px !important;
}

.MuiDataGrid-cellCheckbox {
    margin: 0px 10px 0px 10px;
}

.surveyPt1 .MuiDataGrid-root,
.surveyPt1 .MuiDataGrid-cell--withRenderer {
    border: none !important;
}

.nameField {
    width: 100%;
}

.ageField {
    width: 100%;
    text-align: center;
}

.centeredCheck {
    justify-content: center !important;
}

.surveyPt1,
.surveyPt2 {
    max-width: 1400px;
    margin: auto;
}


/* hide the sorting buttons  */

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root[title~=Sort] {
    display: none !important;
}

.instructionList {
    text-align: left;
    justify-self: center !important;
    line-height: 1.3;
}

.instructionList * {
    margin: 16px auto
}

.centeredBox {
    margin: auto !important;
    border: 1px solid var(--borderColor);
    box-shadow: 4px 4px 4px lightgrey;
    border-radius: 5px;
    width: 50%;
    min-width: 750px;
    padding: 15px 10px 15px 10px !important;
}

.generalButton {
    margin: 20px 160px 20px 160px !important;
    min-width: 100px !important;
    font-size: 18px !important;
}

.survey .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    padding-left: 50px !important;
    font-size: medium;
}

.hiddenError {
    opacity: 0;
    font-size: 10px;
    color: red;
}

.bigHiddenError {
    opacity: 0;
    font-size: 14px;
    color: red;
    padding: 0;
    margin: 0;
}

.slowGradualError {
    -webkit-animation-duration: 8.5s;
            animation-duration: 8.5s;
    -webkit-animation-name: showHide;
            animation-name: showHide;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.gradualError {
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-name: showHide;
            animation-name: showHide;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes showHide {
    from {
        opacity: 0;
    }
    15% {
        opacity: 100;
    }
    55% {
        opacity: 100;
    }
    to {
        opacity: 0;
    }
}

@keyframes showHide {
    from {
        opacity: 0;
    }
    15% {
        opacity: 100;
    }
    55% {
        opacity: 100;
    }
    to {
        opacity: 0;
    }
}

.checkboxWithError {
    display: flex;
    height: 50%;
    flex-direction: column;
    line-height: 0;
}

.wholeColumnStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 14px;
    border: 1px solid var(--borderColor);
    width: 240px;
    height: 500px;
}

.columnBackground {
    width: 100%;
    height: 100%;
    padding: 15px 0px;
    overflow-y: auto;
}

.columnCenterStyle {
    margin: 12px;
}

.smallHeader * {
    font-size: 19px !important;
    padding: 4px;
}

.columnGroupStyle {
    display: flex;
    justify-content: center;
    height: 100%;
}

.activeBackgroundColumn {
    background-color: rgb(245, 245, 245);
}

.smallerText {
    font-size: 12px;
}

.partyBox {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 12px;
    min-height: 50px;
    color: white;
    background-color: rgb(44, 53, 94);
    margin: 8px;
    border-radius: 4px;
}

.activeBackgroundPartyBox {
    background-color: black;
}

.unstyledSubheader {
    margin: 0;
    padding: 0;
    font-size: 12px !important;
    color: white !important;
    text-decoration: none;
    font-weight: normal;
}

.emptySpace {
    padding: 9px;
}

.twoBtns {
    width: 500px;
    margin: auto !important;
    padding-bottom: 60px;
}

.decoratedTextField {
    border-radius: 4px;
    border: 1px solid var(--borderColor);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 40px auto 20px auto!important;
    padding: 12px 54px;
    box-shadow: 4px 4px 4px lightgrey;
}

.loadingCircle {
    position: relative;
    top: 100px
}

.higherLoadingCircle {
    position: relative;
    top: 50px
}

.hiddenInput {
    visibility: hidden;
}

.endSurvey {
    color: var(--main);
    display: block;
    margin-bottom: 15px;
    text-decoration: underline;
}

.subtitle {
    margin-bottom: 15px !important;
}
.spaceTop {
    margin-top: 48px;
}

.confBtn {
    margin: 30px auto auto auto !important;
    background-color: var(--highlight) !important;
    color: white !important;
}

.eventDetailsCard {
    width: 400px;
    height: 300px;
    padding-bottom: 0px;
    margin: 10px auto 0px auto;
    grid-template-rows: [header] 25% [main] 75%;
    display: grid;
    text-align: left
}

.detailsCardHeader * {
    padding: 16px 0px;
}

.detailsCardContent {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.guestTableHader{
	display:flex;
	flex-direction: row;
}

.editGuestListPage .pageNavigationButtons {
    display:			flex;
    flex-direction:		row;
    align-items:		center;
    justify-content:	space-between;
    padding: 0 10% 0 10% !important;
    max-width: 1000px;
    margin: auto;
}

.editGuestListPage .pageNavigationButtons .MuiButton-root {
    margin-inline: 6px;
    max-width: 200px;
    flex: 1 1;
}

.sendSurveyButton {
    display:			flex;
    flex-direction:		row;
    align-items:		end;
    justify-content:	flex-end;
    padding: 0 10% 0 10% !important;
    margin-inline: 6px;
    flex: 1 1;
    max-width: 1000px;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    #dragDropContainer{
        min-width: 250px;
    }
}
.devTable {
    margin: 30px auto;
    width: 780px;
    border: none;
    box-shadow: 4px 4px 4px lightgrey;
    border-radius: 7px;
}

#detailsAside {
    border: none;
    border-radius: 7px;
    height: 500px;
    margin: 30px auto;
    width: 500px;
    box-shadow: 4px 4px 4px lightgrey;
}

#detailsAside .MuiCardContent-root {
    overflow-y: scroll;
    height: 400px;
}

#showDevDetails {
    display: flex;
    flex-direction: row;
}

.devTable .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    padding-left: 10px !important;
}

#refreshPassword {
    border: 1px solid var(--secondary);
    border-radius: 4px;
    width: 180px;
    margin: auto;
    margin-bottom: 10px;
}

.topPadding {
    padding-top: 20px;
}

.passwordSection {
    border: 1px solid var(--secondary);
    width: 50%;
    border-radius: 7px;
    padding: 10px;
    margin: 20px auto auto auto;
    min-width: 300px;
}

.devHeader {
    width: 1410px;
}

.badHeader *{
    color: rgb(199, 4, 4);
}

.innerBoxContent {
    text-align: left;
    line-height: 1.2;
}

.eSummary p {
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-left: 12px;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-before: 4px;
            margin-block-start: 4px;
}

.eSummary {
    margin-top: 16px;
}
.policies {
    text-align: left;
    margin-bottom: 32px;
}

.contactInfo {
    color: inherit;
    margin: 16px 0px 2px 0px;
    text-decoration: none;
}

ul {
    list-style-type: none;
}

.footer a {
    /* background-color: white; */
    color: inherit;
    /* box-shadow: none !important; */
    margin: 4px;
}

.footer .footerInfo a {
    margin: 8px;
}

.policies a {
    word-break: break-word;
}
