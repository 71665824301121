.box {
    border: 1px solid var(--borderColor);
    display: flex;
    flex-direction: column;
    text-align: left !important;
    width: 600px;
    min-height: 320px;
    height: fit-content;
    align-self: center;
    font-size: 14px;
    padding: 12px 12px 12px 0px;
    margin: 12px auto 24px auto !important;
    box-shadow: 4px 4px 2px rgba(150, 150, 150, 0.315) !important;
    overflow: scroll;
}

.betaHeader {
    color: var(--highlight);
}

.box * {
    padding-bottom: 6px;
    padding-top: 12px;
    padding-left: 24px;
    margin: 0;
    color: var(--mainGrey);
}

.box textarea {
    border: 1px solid var(--borderColor);
    padding-left: 12px;
    min-height: 40px;
    margin: 12px 80px 16px 80px;
    font-family: inherit;
    resize: vertical;
    border-radius: 4px;
}

.box textarea:focus,
.box textarea:focus-visible {
    border: 2px solid var(--highlight) !important;
    outline: none;
}

.centered {
    display: flex;
    flex-direction: column;
}

.details {
    padding: 1px 1px 1px 16px;
    margin: auto 80px 2px 64px;
}

.customInput {
    margin-left: 16px;
    margin-right: 80px;
    padding-bottom: 40px;
}

.signature {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
}

.horizontalFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.verticalFlex {
    margin-right: 50px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    text-align: center;
}

.verticalFlex a {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 16px;
}

.innerBox {
    width: fit-content;
    height: fit-content;
    padding: 20px auto;
    min-width: 400px;
    min-height: 200px;
    margin: auto;
    margin-top: 150px;
    background-color: white;
}

.innerBoxHeader {
    background-color: var(--secondary);
    color: white;
}

.innerBoxContent {
    padding: 16px 24px !important;
}

.spacedBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.spacedBtns * {
    margin: 4px auto 8px auto !important;
}

.biggerBox {
    height: 60%;
    min-height: 480px;
}

.hiddenBoxes {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    z-index: 5;
}

.smallButton {
    margin: 40px 30px 0px 30px;
    padding: 10px 25px 10px 25px;
    font-size: 20px;
}

.smallBoxTitle {
    padding-top: 20px;
    padding-bottom: 20px;
}

.profilePage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 24px;
}

.profileElem {
    font-size: 18px;
    padding: 8px 8px 12px 8px;
    font-weight: bold;
}

#sentNotification {
    display: none;
}

.hiddenNote {
    display: none;
    color: var(--lighter);
}

@keyframes disappear {
    from {
        opacity: 100;
        height: 40px;
    }
    75% {
        opacity: 100;
        height: 40px;
    }
    99% {
        height: 40px;
    }
    to {
        opacity: 0;
        visibility: hidden;
        height: 0;
    }
}

.disappearingClass {
    animation-duration: 5s;
    animation-name: disappear;
    opacity: 0;
    height: 0;
}

.bottomButtons {
    margin: 0px 4px 0px 4px;
}

.profileData {
    font-weight: normal;
    padding-left: 10px;
    font-size: 16px;
}

.editable {
    display: none;
    border: 1px solid black;
    margin: 0px 8px 0px 8px;
}

.homeButton {
    width: 100px;
    margin: 24px auto 24px auto !important;
    font-size: 24px !important;
}

#editProfileError {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.editProfError {
    color: red;
    font-size: 12px;
    margin-left: 6px;
    display: none;
}

.picButton {
    margin: 0px 620px 12px 0px !important;
    padding-top: 0 !important;
}

#editProfPic {
    display: none;
}

#fileChooser {
    background-color: rgba(228, 228, 228, 0.555);
    border: 0.3px solid black;
    text-align: center;
    padding: 5px !important;
    margin-left: 10px !important;
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
}

#fileChooser:hover {
    background-color: rgba(228, 228, 228, 0.888);
    cursor: pointer;
}

.closeHorizontalFlex {
    display: flex;
    justify-content: flex-start;
}

.passInputs input {
    border: 1px solid black;
    border-radius: 4px;
}

.passInputs label {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 55%;
    min-width: 180px;
    margin: auto auto 10px auto;
}

.passInputs {
    margin-bottom: 25px;
}

.passChangeFooter {
    margin: auto 24px auto 24px;
}

.closeBox {
    display: flex !important;
    margin: 5px!important;
}

.closeButtons {
    margin: auto;
    width: 600px;
}

.surveyBtns {
    width: 100%;
    margin: auto;
    max-width: 1000px;
}

.surveyPreview {
    width: 900px;
    margin: 76px auto !important;
}

.previewFooter {
    background-color: var(--borderColor);
    height: 60px;
    display: flex;
}

.previewFooter * {
    margin: auto !important;
}

.closeBtn {
    color: white;
}

.editSurvey .surveyBtns .horizontalFlex .MuiButton-root {
    flex: 1;
    max-width: 235px;
    margin: 20px !important;
}

.editSurvey .surveyBtns .horizontalFlex.pageNavBtns .MuiButton-root {
    max-width: 164px;
}


@media only screen and (max-width: 768px) {
    .verticalFlex {
        margin-inline: 0;
    }
    .box {
        max-width: 90%;
    }
    .box>* {
        margin-inline: 12px;
        line-height: 1.8;
    }
    .box textarea {
        margin-inline: 28px;
    }
    .horizontalFlex:first-child {
        flex-direction: column;
        align-items: center;
    }

    .editSurvey .surveyBtns .horizontalFlex .MuiButton-root{
        width: 100%;
    }
    .surveyBtns {
        width: 100%;
    }
    .surveyBtns .basicBtn {
        margin: 12px !important;
    }
    .surveyPreview {
        max-width: 900px;
        width: 100%;
    }
    .closeButtons {
        width: 100%;
    }

    .editSurvey .surveyBtns .horizontalFlex button.MuiButton-root{
        margin: 6px 4px !important;
    }
}