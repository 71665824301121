.seatStat {
    color: rgb(75, 75, 75);
    font-size: 28px;
    margin: 0px;
}

.addTableBtn {
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 20px !important;
    padding: 12px 22px !important;
}

.generateBtn {
    margin-left: auto !important;
    font-size: 20px !important;
    margin-right: 10px !important;
}

.fakeTableSection {
    align-items: center;
    justify-items: center;
    display: flex;
    width: 100%;
    height: 100%;
}

.fakeTableBox {
    margin: 6px;
    border: none;
    box-shadow: none !important;
    height: 100px;
}

.statLabel {
    margin: 0px 0px 12px 0px;
    color: rgb(75, 75, 75);
}

.titleBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pageHeader {
    margin: 20px 24px auto 24px;
}

.titleBar h1 {
    font-size: 32px;
    margin: 0;
}


/* Hide scrollbar for Chrome, Safari and Opera */
/*
.guestTable::-webkit-scrollbar {
    display: none;
} */

.guestTable {
    min-height: 500px;
    max-height: 700px;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    /* scrollbar-width: none; */
    /* Firefox */
}

.guestTable button {
    box-shadow: none;
    color: white;
    margin-left: 10px;
}

.tableHeader {
    background-color: white !important;
    color: var(--main) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.smallHR {
    width: 80%;
    padding: 0;
    margin: 0 auto;
}

.cornerText {
    font-size: 10px;
    float: left;
    color: var(--mainGrey);
}

.tableBox {
    margin: 6px;
    border: 1px solid var(--borderColor);
}

.scrollable {
    overflow: scroll;
    margin-top: 0px !important;
}

/* .seatingChart::-webkit-scrollbar {
    display: none;
} */

.seatingChart {
    display: flex;
    flex-direction: column;
    max-height: 700px;
    -ms-overflow-style: none;
    /* IE and Edge */
    /* scrollbar-width: none; */
    /* Firefox */
}

/* .tableBody {
    max-width: 90%;
    padding: 5px;
} */

.seat {
    list-style: none;
    text-align: left;
    list-style-type: none;
    border-bottom: 1px solid black;
    padding: 3px 8px 1px 12px;
    display: flex;
    justify-content: space-between;
}

ul {
    margin: 0px;
    padding: 0px;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.centeredCell {
    text-align: center !important;
    justify-content: center !important;
}

.nameCell {
    margin-left: 7px;
}

.centeredContent {
    justify-content: center;
}

.tableInSeatDash {
    min-height: 200px;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.unseatedSection {
    min-height: 500px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.overBackground {
    background-color: rgb(245, 245, 245);
}

.guestName {
    padding: 4px;
    background-color: white;
    color: var(--footerColor);
    border: 1px solid var(--borderColor);
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    margin: 0 20px 15px 20px;
}

.guestName:last-child {
    margin-bottom: 0;
}

.placedGuest {
    box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
    filter: brightness(100%);
    animation-duration: 0.25s;
    animation-name: putDown;
}

.draggingGuest {
    filter: brightness(85%);
    box-shadow: 7px 7px 20px rgb(0 0 0 / 15%);
    animation-duration: 0.25s;
    animation-name: pickUp;
}

@keyframes pickUp {
    from {
        filter: brightness(100%);
        box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
    }
    to {
        filter: brightness(85%);
        box-shadow: 7px 7px 20px rgb(0 0 0 / 15%);
    }
}

@keyframes putDown {
    from {
        filter: brightness(85%);
        box-shadow: 7px 7px 20px rgb(0 0 0 / 15%);
    }
    to {
        filter: brightness(100%);
        box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
    }
}

.overFull {
    color: red !important;
    background-color: var(--borderColor);
    font-weight: bold;
    font-size: 18px;
}

.tableFillLevel {
    background-color: var(--borderColor);
    font-size: 16px;
    color: black !important;
}

.topTableHeader {
    margin-bottom: 0 !important;
    text-align: center;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    display: flex !important;
    justify-content: space-between;
}

.topTableHeader * {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    text-align: center;
    padding: 6px !important;
}

.tableOptions {
    display: flex !important;
    justify-content: space-between;
    align-content: center;
    margin: 0px 30px;
}

.clearButton {
    height: 30px !important;
    color: black !important;
    background-color: white !important;
    align-self: center !important;
}

.seatingChartHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.noShadow {
    box-shadow: none !important;
}

.whiteTxtBtn {
    color: white !important;
    font-size: 14px !important;
}

.secondaryBtn {
    background-color: var(--highlight) !important;
    margin-right: 4px !important;
    filter: brightness(90%);
}

.greyTxtBtn {
    color: var(--mainGrey) !important;
    font-size: 14px !important;
    box-shadow: none !important;
    border: none !important;
}

.fullLengthSection {
    width: 80%;
    max-width: 1200px;
    min-width: 800px;
    margin: auto;
}

.tableName {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

.firstCard {
    padding-top: 20px;
}

.topCard {
    margin: 48px auto;
    box-shadow: 7px 7px 4px rgba(172, 171, 171, 0.384) !important;
}

.tableTitle {
    background-color: var(--secondary);
    color: white;
    font-size: 22px;
}

.cardHeader {
    background-color: var(--secondary);
    text-align: left;
    color: white;
    padding: 8px 12px !important;
    font-size: 22px;
    position: sticky;
    top: 0;
    z-index: 1;
}

@media only screen and (max-width: 1024px) {
    .seatingChart .cardHeader div:last-child {
        display: flex;
    }
}

@media only screen and (min-width: 1024px) {
    .seatingChart .cardHeader div:last-child {
        display: flex;
        align-items: center;
    }
    .switchLabel {
        width: fit-content !important;
    }
}

.seatingChart .cardHeader div:last-child {
    /* width: 100%; */
    margin-left: 12px;
    margin-top: 8px;
}

.fullCardHeader {
    width: 100%;
}

.cardHeader * {
    font-size: 22px;
}

.seatDashCard {
    box-shadow: 4px 4px 4px rgba(172, 171, 171, 0.384);
    border: 1px solid var(--borderColor);
}

.returnDashBtn {
    color: white !important;
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
}

.downloadBtn {
    padding-right: 10px !important;
}

.searchBar {
    width: 85%;
}

.stickySearch {
    position: sticky !important;
    top: 0px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 3px 3px rgba(167, 165, 165, 0.253);
    height: 64px;
    width: 100%;
}

.wrappedP {
    overflow: wrap;
    padding: 2px;
}

.switchLabel {
    color: white;
    font-size: 10px;
    display: inline-flex;
    flex-direction: column;
    margin-right: 8px;
}


.infoError {
    color: var(--highlight) !important;
    float: right;
    width: 14px;
    height: 14px;
    padding: 0 !important;
    cursor: help !important;
}

.editTableNum {
    color: var(--secondary) !important;
    width: 18px;
    height: 18px;
    padding: 0 !important;
}

.editTableNum:disabled {
    color: var(--borderColor) !important;
}

.spacedSpan {
    padding: 0px 6px;
}

.smallClose {
    color: black !important;
    padding: 0 !important;
    width: 24px;
    box-shadow: none !important;
    height: 24px;
}

#sortMenu {
    display: flex;
    justify-content: center;
    padding: 3% 0 0 0;
    gap: 0.5ch;
}

.seatDashCard button {
    box-shadow: none !important;
}

@media only screen and (max-width: 768px) {
    .fullLengthSection{
        min-width: unset;
        width: auto;
    max-width: calc(100% - 10px);
    margin-bottom: -300px;
    }

    .fullLengthSection h1.title{
        font-size: 28px;
        margin: 25px auto 0px auto;
    }

    .topCard{
        margin-top: 20px;
    }

    .fullLengthSection .MuiGrid-root.dashBody>.MuiGrid-root {
        padding-left: 0px;
    }

    .fullLengthSection .MuiGrid-root.dashBody>.MuiGrid-root.setDashCardContainer {
        position: absolute;
        top: 100%;
        z-index: 6;
        width: 100%;
        padding-top: 28px;
        height: 29vh;
    }

    .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer {
        width: 100%;
        margin-inline: auto;
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        padding-top: 16px;
        height: 100%;
    }


    .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer .seatDashCard {
        height: 100%;
    }

    .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer .seatDashCard .guestTable.centeredCell{
        min-height: unset;
        max-height: calc(100% - 42px);
    }

    .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer .seatDashCard .guestTable.centeredCell .unseatedSection{
        min-height: unset;
        display: flex;
        flex-wrap: wrap;
    }

    .unseatedSection .guestName{
        width: 100%;
        max-width: calc(50% - 30px);
        box-sizing: border-box;
        margin: 0 15px 15px 15px
    }

    .unseatedSection .guestName:last-child{
        margin-bottom: 15px;
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .cardHeader .MuiCardHeader-content .MuiTypography-root {
        white-space: nowrap;
        font-size: 20px;
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .cardHeader .MuiCardHeader-action .switchLabel{
        display: none
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .cardHeader .MuiCardHeader-action .pipeSeparator{
        display: none;
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .cardHeader .MuiCardHeader-action button {
        padding: 3px 6px;
        min-width: unset;
        font-size: 11px !important;
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .cardHeader .MuiCardHeader-action  {
        margin: 0;
        display: flex;
        align-self: center;
    }

    .seatingChart.seatDashCard>.MuiGrid-container{
        height: 60vh;
        flex-direction: column;
        overflow-x: scroll;
    }

    .seatDashCard .MuiGrid-root.MuiGrid-container>.MuiGrid-item {
        flex-basis: 50%;
        max-height: 50%;
    }

    .seatDashCard .MuiGrid-root.MuiGrid-container.firstCard>.MuiGrid-item {
        margin-inline: auto;
    }

    .seatDashCard .MuiGrid-root.MuiGrid-container.firstCard>.MuiGrid-item:last-child {
        grid-column: 1 / span 2;
        padding-block: 4px 30px;
    }

    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .tableBox {
        height: calc(100% - 12px);
        box-sizing: border-box;
    }


    .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .tableBox .tableInSeatDash {
        min-height: unset;
        height: calc(100% - 60px - 8px);
        box-sizing: border-box;
        overflow: scroll;
    }

    .fullLengthSection .dashBody .tableBox .tableHeader .topTableHeader {
        min-height: unset;
    }

    .fullLengthSection .dashBody .tableBox .tableHeader .topTableHeader .tableName{
        padding: 0px !important;
        padding-left: 4px !important;
    }


        .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer .seatDashCard .guestTable.centeredCell .stickySearch{
            position: absolute !important;
            width: 50%;
            top: 0;
            right: 0;
            margin-top: 36px;
            margin-right: 4px;
            height: unset;
            border-radius: 4px;
        }


        .fullLengthSection .dashBody.MuiGrid-root.seatingChartContainer .seatDashCard .guestTable.centeredCell .searchBar{
            background: white;
            border-radius: 4px;
            width: 100%;
            margin: 0!important;
        }

        .fullLengthSection #searchBar{
            padding: 2px 14px
        }

        .firstCard .MuiCardHeader-title{
            font-size: 1.2rem;
        }


        .fullLengthSection .dashBody.firstCard {
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding-top: 0px;
            padding-bottom: 10px;
            display: grid;
            grid-template-columns: 50% 50%;
        }
        .fullLengthSection .dashBody.firstCard>div {
            max-width: 85%;
        }

        .fullLengthSection .topCard .cardHeader {
            flex-direction: column-reverse;
        }

        .fullLengthSection .topCard .cardHeader .MuiCardHeader-action {
            align-self: center;
        }

        .fullLengthSection .dashBody .MuiGrid-root .seatDashCard .tableBox .tableFillLevel {
            padding: 4px;
            font-size: 12px;
        }
    /* .fullLengthSection>.MuiGrid-root.MuiGrid-container{
        height: 100vh;
    } */

    /* .fullLengthSection>.MuiGrid-root.MuiGrid-container.dashBody>.MuiGrid-item:first-child{
        position: fixed;
        z-index: 9;
        height: 33%;
        box-sizing: border-box;
        bottom: 0;
        left: 0;
    } */
}