.MuiDialog-paper .forgotPasswordLink{
    margin-bottom: 14px;
    display: inline-block;
}

.close {
    margin: 0px;
    border: none !important;
    border-radius: 100% !important;
    margin-right: 15px !important;
    max-width: fit-content;
    box-shadow: none !important;
    min-width: fit-content !important;
}

.closeButton {
    width: 100%;
    justify-content: right;
    display: inline-flex;
}

.loginHeader {
    padding-top: 5px !important;
}