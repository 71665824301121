.header {
    background-color: white;
    border-bottom: 2px solid hsla(0, 0%, 81%, 0.87);
    box-shadow: 0px 10px 0px rgba(207, 207, 207, 0.192);
    margin: 0 !important;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0;
    z-index: 2;
}

.stickTop {
    position: sticky;
    top: 0;
}

.footer {
    background-color: var(--footerColor);
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 24px 0 8px 0;
    color: #fff;
    font-size: 14px;
}

.footer .footerInfo {
    color: inherit;
    margin: 8px 34px 2px 34px;
    text-decoration: none;
}


.copyright {
    /* margin-top: 20px; */
    color: rgba(255, 253, 253, 0.3)
}

.logo {
    height: 35px;
    align-self: center;
    margin: 0px 0px 0px 10px;
    padding: 0;
}

.clickable:hover {
    cursor: pointer;
}

.socialIcons {
    color: white;
    display: flex;
    justify-content: space-around;
    width: 8%;
    padding-top: 12px;
    margin: auto;
}

#login {
    display: flex;
    flex-direction: row;
}

.loginUserInput {
    display: flex;
    flex-direction: column;
    align-self: center;
}

#username {
    margin-bottom: 3px;
}

input {
    padding: 5px 3px;
    margin: 2px;
    border: none;
}

.profilePic {
    margin: auto 20px auto 0px;
    cursor: pointer;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    color: var(--secondary) !important;
    border: 1px solid var(--main);
    background-color: white !important;
}

.profilePic:hover {
    filter: brightness(95%);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

footer {
    position: absolute;
    bottom: 0;
}

.peachLine {
    background-color: var(--highlight);
    border: 0px;
    width: 60%;
    min-width: 400px;
    max-width: 80% !important;
    height: 3px;
    border-radius: 3px;
    margin: 30px auto;
}

.lowImg {
    margin: 10px auto 0px auto;
}

.highTitle {
    margin-top: 0px;
}

.profileBox {
    display: flex;
    align-content: center;
}

.profileAvatarButton {
    padding-top: 0px !important;
}

.profileMenuDropdown {
    padding-right: 8px;
    padding-left: 8px;
}

.greyBtn {
    background-color: #efefef !important;
    color: var(--secondary) !important;
}

.loginBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 260px;
    margin: 0px 10px 0px 0px;
    padding: 0px;
    align-content: center;
}

.contactInfo:hover{
    text-decoration: underline !important;
    text-decoration-color: var(--primary) !important;
}

@media only screen and (max-width: 600px) {
    .logo {
        height: 27px;
        /* For beta look */
        margin-top: 8px;
    }
    .loginBtns {
        width: auto;
        margin-right: 3px;
        box-sizing: border-box;
        height: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .basicBtn {
        width: unset;
    }
    .loginBtns .basicBtn {
        margin: 3px !important;
        font-size: 14px !important;
    }
}

.footer .logo {
    height: 24px;
    margin: 0;
    margin-bottom: 16px;
}