.boxes {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 90%;
}

#addEventBtn {
    margin: 20px auto 20px auto;
    padding: 10px 0px 10px 0px;
    width: 90%;
}

.table {
    margin: 30px auto;
    width: 750px;
    border: none;
    box-shadow: 4px 4px 4px lightgrey;
    border-radius: 7px;
    box-sizing: border-box;
}

.MuiDataGrid-root {
    border-radius: 7px !important;
    border: 0.5px solid rgb(241, 241, 241) !important;
}

.MuiDataGrid-main {
    border: none;
}

.MuiDataGrid-columnHeaderWrapper {
    padding-left: 12px;
    background-color: var(--secondary);
    color: white;
    font-weight: bold;
    font-size: 16px;
    border-radius: 7px 7px 0px 0px;
    padding-right: 12px;
}

.MuiDataGrid-cell {
    border: none;
}

.MuiDataGrid-columnsContainer {
    border: none;
    border-radius: 7px 7px 0px 0px;
}

.MuiDataGrid-columnSeparator {
    opacity: 0 !important;
}

.MuiDataGrid-cell {
    border: none !important;
}

.MuiDataGrid-row {
    border: none;
}

.MuiDataGrid-cell--textLeft {
    border: none;
}

.userDashContent .table .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    padding-left: 24px;
}

.dashBtn {
    font-size: 14px !important;
}

.plusBtn {
    color: var(--lighter);
    position: relative;
    left: -8px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: white;
}

.leftBtn {
    justify-self: flex-start;
    width: fit-content;
}

.userDashContent {
    width: fit-content;
    margin: auto auto 40px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.userDashFooter {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.userDashText {
    color: var(--lighter);
}

.userDashContent button.MuiIconButton-root {
    box-shadow: none !important;
}

@media only screen and (max-width: 768px) {
    .userDashContent{
        width: 100%;
    }

    .userDashContent button.MuiButton-root{
        width: 100%;
        max-width: 200px;
        margin-inline: auto;
    }


    .userDashContent .table {
        margin: 10px 4px 18px 4px;
        width: calc(100% - 8px);
        /* padding-bottom: 20px; */
    }

    .userDashContent .table .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent{
        height: 100% !important;
        overflow-y: auto;
    }

    .userDashContent .MuiDataGrid-main .MuiDataGrid-row{
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .userDashContent .MuiDataGrid-main .MuiDataGrid-row>.MuiDataGrid-cell:first-child {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .userDashContent .MuiDataGrid-main .MuiDataGrid-row>.MuiDataGrid-cell:first-child>p {
        margin: 0;
        line-height: normal;
    }

    .userDashContent .MuiDataGrid-main .MuiDataGrid-row>.MuiDataGrid-cell:last-child {
        padding-left: 6px;
    }

    .userDashContent .MuiDataGrid-cell .cellSubtitle{
        padding-left: 8px;
        font-style: italic;
        color: #aaa;
    }

    .userDashContent .MuiDataGrid-cell .dateSubtitle{
        font-size: 10px;
    }

    .userDashContent .MuiDataGrid-cell .countdownSubtitle{
        font-size: 10px;
    }

    .userDashFooter{
        flex-direction: column-reverse;
        align-items: center;
    }

    .userDashFooter .MuiButton-root{
        margin: 16px;
    }
}