

#guestListFileUpload {
	display: none;
}

#inputTestTag {
	font-size: 18px;
	display: block;
	width: 100%;
	border: none;
	text-transform: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	margin: 0;
	padding: 0;
}

#dragDropContainer{
	position: relative;
	margin: 0 0 15px;
	border: 2px dotted lightgray;
	padding: 35px 20px;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: transparent;
	min-width: 400px;
	width: 800px;
	height: 200px;
}

.uploadFileIcon {
	width: 88px;
	height: 88px;
	align-self: center;
}

.fileUploadButtonSection {
	position: relative;
	top: 92px;
	display: grid;
	grid-template-columns: [first] 20% [second] 80%;
	column-gap: 54px;
	height: 0;
}

.fileUploadButtonSection p{
	font-size: 22px;
}

.fileUploadSection {
	display: flex;
	flex-direction: column;
	max-width: 1000px;
	margin: auto;
	justify-content: center;
	align-items: center;
}

.horizontalOr {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    max-width: 1200px;
    align-self: center;
    padding: 0 80px;
    margin: 40px auto 74px auto;
}

.horizontalBar {
    height: 3px;
    background: #CFCFCF;
    width: 100%;
}

.horizontalBar span{
	font-size: 20px;
}

.manualGuestListSection{
	max-width: 1200px;
	margin: auto;
}

.addGuestRowBottomControl{
	text-align: left;
}

.buttonControlGroup{
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 900px;
	margin: 60px auto 0 auto;
}

.buttonControlGroup > * {
    margin-inline: 2px;
}

.addButton {
	flex: 1 1;
	padding: 15px;
	font-size: 18px;
}

#addIndividualButton{
	border-radius: 6px;
}

#addCoupleOrGroupButton{
	border-radius: 6px;
}

.buttonPopOut {
	max-width: 900px;
	background: white;
	margin: 12px auto;
	padding: 10px 18px 22px 18px;
	box-sizing: border-box;
	display: grid;
	outline: 2px solid #cfcfcf;
	border-radius: 8px;
	row-gap: 3px;
   	column-gap: 12px;
}

.individualPopOut{
	grid-template-columns: [first] auto [second] auto [third] auto [fourth] 165px;
}

.groupPopOutHeader {
    background: var(--secondary);
    color: white;
    text-align: left;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 17px;
    font-weight: bold;
}

.groupPopOut.buttonPopOut{
	grid-template-columns: [first] 60% [second] auto;
	padding-top: 18px;
	row-gap:0px;
	column-gap: 0px;
	padding:0;
}

.groupInformationHeader{
	border-radius: 8px 0px 0px 0px;
    padding-left: 20px;
}

.groupMembersHeader{
	border-radius: 0px 8px 0px 0px;
    padding-left: 40px;
}

.sendSurveyInputSection{
	margin-top: auto;
	margin-bottom: auto;
}

.MuiFormControl-root{
	width: 100%;
}

.plusOneInputGroup{
	display: flex;
}

.plusOneInput{
	flex:1;
}

.compactNumberComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
	margin-top: -4px;
	margin-inline:auto;
}

.numberComponentAction {
    display: flex;
    flex-direction: row;
    background: #f1f1f1;
    height: 40px;
    border-radius: 6px;
    border: 2px solid #d9d9d9;
    position: relative;
	margin-left: 18px;
}

.compactNumberComponent .MuiFormControl-root {
    width: 48px;
    margin-top: 0px !important;
    position: absolute;
    left: 50%;
    margin-left: -24px !important;
    z-index: 1;
}

.compactNumberComponent .MuiFormControl-root .MuiInputBase-root{
	background: white;
}

.compactNumberComponent .MuiInputBase-input{
	text-align: center;
}

.plusButton{
	padding-left: 30px;
}

.minusButton{
	padding-right: 30px;
}

.compactNumberComponent .numberComponentActionButton {
	width: 64px;
    height: 40px;
    box-shadow: unset !important;
}

.compactNumberComponent .MuiFormHelperText-root {
    position: absolute;
    width: 221px;
    top: 44px;
    left: -91px;
}


.addButtonGroup {
	grid-column: span 4;
	text-align: right;
}
.addButtonGroup button {
    width: 130px;
}

.addButton{
	color:#5E5E5E;
	background-color: #CFCFCF;
}

.addButton.activeButton{
	background-color: var(--secondary);
	color: white;
}


.unknownNameCheckboxGroup{
	display: flex;
	align-items: center;

}

.groupInfo {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 89px 80px;
	grid-gap: 20px;
	gap: 20px;
	padding-right: 10px;
	grid-column: 1;
	padding-left: 18px;
    padding-top: 21px;
}

.groupMembers{
	position: relative;
    padding-left: 40px;
        padding-left: 40px;
    padding-right: 18px;
    padding-top: 10px;
}

.groupPopOut .addButtonGroup{
    padding-bottom: 26px;
    padding-right: 18px;
    padding-top: 4px;
}


.groupMembers:before {
    content: " ";
    border-left: 1px solid #CFCFCF;
    height: 100%;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    left: 20px;
}

.downloadTemplate {
    color: var(--main);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: right;
    max-width: 780px;
    margin: 5px auto 20px auto;
    width: 100%;
}

.downloadTemplate * {
	align-self: center;
}

.backendError {
    color: var(--highlight);
}

.errorText {
	color: var(--highlight);
    font-size: 10px !important;
	padding: 4px 8px;
	margin-bottom: 0px !important;
}

.success {
	color: var(--highlight);
	margin-bottom: 0px;
	padding-bottom: 0px;
}

@media only screen and (max-width: 768px) {
	.fileUploadSection{
		max-width: 100%;
		padding-left: 4px;
		padding-right: 4px;
	}

	.downloadTemplate{
		width: unset;
	}

	.uploadFileIcon {
		width: 42px;
		height: 42px;
		margin-left: auto;
	}

	.fileUploadButtonSection{
		grid-column-gap: none;
		column-gap: normal;
		top:5px;
	}

	.fileUploadButtonSection>section{
		max-width: 80%;
		line-height: 1.5;
	}

	#dragDropContainer{
		margin-inline: 55px;
		width: 100%;
		box-sizing: border-box;
		height: 172px;
	}

	.manualGuestListSection .addButton {
		margin: 4px;
		width: 100%;
		max-width: 190px;
		line-height: 1.5;
		padding-left: 6px;
		font-size: 14px;
		padding-right: 6px;
	}

	.buttonPopOut{
		display: flex;
    	flex-direction: column;
		margin-inline: 20px;
	}

	.groupPopOutHeader{
		display: none;
	}

	.groupInfo{
		display: flex;
		flex-direction: column;
		padding-top: 9px;
		gap: 12px;
	}

	.groupMembers{
		padding-left: 18px;
	}

	.groupMembers>p{
		margin-top:58px;
	}

	.groupMembers:before{
		border-top: 1px solid #CFCFCF;
		height: 100%;
		position: absolute;
		top: 32px;
		width: 100%;
		left: 0px;
		border-left: none;
	}

	.manualGuestListSection{
		padding-left: 4px;
		padding-right: 4px;
	}
}