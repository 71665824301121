@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
:root {
    --main: #00A6A0;
    --mainAlpha: rgb(0, 166, 160, .93);
    --lighter: #03c9c2;
    --secondary: #1F6BAE;
    --highlight: #F57A75;
    --borderColor: #cfcfcf;
    --mainGrey: #5e5e5e;
    --footerColor: #1F6BAE;
    white-space: pre-wrap;
}

.pricingInfo .tryPlacecardButtonGroup {
    margin-inline: auto;
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

body,
html,
input,
button {
    font-family: 'Ubuntu', sans-serif;
}

th,
h1,
h2,
h3,
h4 {
    font-weight: normal;
}

body {
    text-align: center;
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    min-height: 100vh;
    font-weight: normal;
}

small {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
}

.loginInputs {
    width: 300px !important;
}

.specialAuthenticators li {
    padding: 4px 0px !important;
    border: 3px solid var(--secondary);
    border-radius: 4px;
}

.specialAuthenticators li:hover {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    background-color: rgb(245, 245, 245);
    filter: brightness(90%);
}

.specialAuthenticators {
    text-align: left;
    width: 300px;
    margin: auto;
    padding: 0px !important;
}

.smallSpacing {
    margin: 2px auto 12px auto!important;
}

.smallLink {
    font-size: 12px !important;
    color: var(--highlight);
    text-decoration: underline;
    text-align: left;
    width: 300px;
    margin: 4px auto auto auto !important;
}

.smallLink:hover {
    filter: brightness(75%);
    cursor: pointer !important;
}

.smallLink:visited {
    color: var(--secondary);
}

.smallLink:active {
    filter: brightness(65%);
}

.MuiDialog-paperWidthSm {
    width: 500px;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

button {
    border-radius: 4px;
    border: none;
    /* box-shadow: 2px 2px 6px rgba(0,0,0,0.1); */
    padding: 2px 6px;
    text-transform: none !important;
}

button:hover {
    filter: brightness(90%);
    /* box-shadow: 4px 4px 8px rgba(0,0,0,0.3); */
}

.title {
    /* letter-spacing: 2px; */
    font-size: 36px;
    margin: 40px auto 6px auto;
    color: var(--secondary);
}

.subtitle {
    font-size: 16px;
    margin: 12px auto 24px auto;
    color: var(--mainGrey);
    max-width: 70%;
    min-width: 400px;
    width: 50%;
    overflow-wrap: normal;
}

.finePrint {
    font-size: 10px !important;
}

.rectangleButton {
    display: block;
    padding: 0px 25px 0px 25px;
    height: 80%;
    align-self: center;
    margin: 5px 20px 5px 15px;
    font-size: 24px;
    letter-spacing: 1px;
    background-color: rgb(225, 225, 225);
    color: black;
    font-style: normal;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    cursor: default;
}

.rectangleButton:hover {
    filter: brightness(90%);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

.smallerButton {
    width: 20%;
    min-width: 240px;
    margin: 10px auto 50px auto;
    padding: 10px;
}

.verticalContainer {
    display: flex;
    flex-direction: column;
}

.verticalContainer button {
    margin: 2px;
}

.horizontalContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content {
    padding-bottom: 172px;
}


/* input {
  /* box-shadow: 3px 3px 5px rgba(0,0,0,0.2); */


/* border-radius: 2px; */


/* border: 2px solid rgb(228, 228, 228) !important; */


/* } */

label {
    color: var(--secondary);
}

.basicBtn {
    width: 140px;
    margin: 20px auto !important;
    letter-spacing: 1px;
    font-size: 16px !important;
}

.fitBtn {
    width: fit-content;
}

.lowBtn {
    margin-top: 60px !important;
}

.highBtn {
    margin: 8px auto !important;
}

.iconBtn {
    color: var(--secondary) !important;
    margin: 0px 8px;
}

.blueBtn {
    background-color: var(--secondary) !important;
    color: white !important;
}

.smallBtnFnt {
    font-size: 14px !important;
}

.smallMargin {
    margin: 8px auto 0px auto !important;
}

button {
    box-shadow: 2px 3px 3px rgba(73, 73, 73, 0.363) !important;
}

.lowText {
    margin: 50px auto 10px auto;
}

.spacedBottom {
    margin-bottom: 32px !important;
}

.lowTitle {
    padding-top: 24px;
}

.textLink {
    color: var(--highlight);
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .content>.title,
    .content>section>.title {
        margin-inline: 40px;
        margin-bottom: 38px;
    }
    .content>.subtitle,
    .content>section>.subtitle {
        width: 100%;
        box-sizing: border-box;
        min-width: unset;
        max-width: unset;
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 18px;
    }

    .content {
        padding-bottom: 206px;
    }

    p.subtitle {
        line-height: 26px;
    }

    .title {
        letter-spacing: 0;
    }
}