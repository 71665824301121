.devTable {
    margin: 30px auto;
    width: 780px;
    border: none;
    box-shadow: 4px 4px 4px lightgrey;
    border-radius: 7px;
}

#detailsAside {
    border: none;
    border-radius: 7px;
    height: 500px;
    margin: 30px auto;
    width: 500px;
    box-shadow: 4px 4px 4px lightgrey;
}

#detailsAside .MuiCardContent-root {
    overflow-y: scroll;
    height: 400px;
}

#showDevDetails {
    display: flex;
    flex-direction: row;
}

.devTable .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    padding-left: 10px !important;
}

#refreshPassword {
    border: 1px solid var(--secondary);
    border-radius: 4px;
    width: 180px;
    margin: auto;
    margin-bottom: 10px;
}

.topPadding {
    padding-top: 20px;
}

.passwordSection {
    border: 1px solid var(--secondary);
    width: 50%;
    border-radius: 7px;
    padding: 10px;
    margin: 20px auto auto auto;
    min-width: 300px;
}

.devHeader {
    width: 1410px;
}

.badHeader *{
    color: rgb(199, 4, 4);
}

.innerBoxContent {
    text-align: left;
    line-height: 1.2;
}

.eSummary p {
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-left: 12px;
    margin-block-end: 0;
    margin-block-start: 4px;
}

.eSummary {
    margin-top: 16px;
}