.vertical-form {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.formBox {
    border: 1px solid var(--borderColor);
    width: fit-content;
    margin: auto;
    padding: 10px 80px;
    border-radius: 4px;
    box-shadow: 2px 2px 2px rgb(202, 202, 202);
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 15px;
}

.noShadow {
    box-shadow: 0;
}

.MuiOutlinedInput-notchedOutline {
    border: 3px solid var(--borderColor);
    border-radius: 7px;
}

.MuiFormControl-root {
    width: 280px;
    margin: 8px auto !important;
}

.formError {
    color: var(--highlight);
    font-size: 10px;
    display: none;
}

.passEye {
    align-self: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.yesEye {
    display: none;
}

.pageError {
    color: var(--highlight);
    display: none;
    margin-top: 0;
}

.shownError {
    color: var(--highlight);
    margin-top: 0;
}

.successMessage {
    color: var(--main);
    text-align: center;
}

.returnToEvents {
    font-size: 90%;
    color: var(--highlight);
    text-decoration: underline;
    display: inline-flex;
    align-items: center;
    margin: auto;
    padding-bottom: 15px;
}

.returnToEvents:hover {
    cursor: pointer;
}

.backArrow {
    font-size: 90% !important;
    color: var(--highlight) !important;
}

.messages {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {

    .content>.vertical-form{
        margin-inline: 12px;
    }

    .formBox{
        padding-left: 40px;
        padding-right: 40px;
    }

}