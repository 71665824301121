.tinyButton {
    padding: 8px 16px 8px 16px;
    margin: 20px 100px 10px 100px;
}

.smallSearch {
    width: 550px;
    margin: auto;
    display: flex;
    justify-content: left;
    box-shadow: none;
    padding: none;
}

.lowSub {
    margin-top: 70px;
    padding: 0px 70px 0px 70px;
}

.lowButtons {
    margin: 200px 70px 0px 70px;
}

.smallP {
    font-size: 14px;
    padding: 2px;
}

.guestConfirmation {
    max-width: 1000px;
    margin: auto;
}

.textInput {
    margin-top: 10px !important;
}

.survey {
    margin: 30px auto;
    border: none;
    box-shadow: 4px 4px 4px lightgrey;
    border-radius: 7px;
    margin: auto !important;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    width: 40%;
    min-width: 500px;
    /* border: 1px solid grey; */
    /* border-radius: 5px; */
}


/* Hide scrollbar for Chrome, Safari and Opera */

.survey::-webkit-scrollbar {
    display: none;
}

.MuiDataGrid-row {
    margin: 0px !important;
}

.MuiDataGrid-cellCheckbox {
    margin: 0px 10px 0px 10px;
}

.surveyPt1 .MuiDataGrid-root,
.surveyPt1 .MuiDataGrid-cell--withRenderer {
    border: none !important;
}

.nameField {
    width: 100%;
}

.ageField {
    width: 100%;
    text-align: center;
}

.centeredCheck {
    justify-content: center !important;
}

.surveyPt1,
.surveyPt2 {
    max-width: 1400px;
    margin: auto;
}


/* hide the sorting buttons  */

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root[title~=Sort] {
    display: none !important;
}

.instructionList {
    text-align: left;
    justify-self: center !important;
    line-height: 1.3;
}

.instructionList * {
    margin: 16px auto
}

.centeredBox {
    margin: auto !important;
    border: 1px solid var(--borderColor);
    box-shadow: 4px 4px 4px lightgrey;
    border-radius: 5px;
    width: 50%;
    min-width: 750px;
    padding: 15px 10px 15px 10px !important;
}

.generalButton {
    margin: 20px 160px 20px 160px !important;
    min-width: 100px !important;
    font-size: 18px !important;
}

.survey .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    padding-left: 50px !important;
    font-size: medium;
}

.hiddenError {
    opacity: 0;
    font-size: 10px;
    color: red;
}

.bigHiddenError {
    opacity: 0;
    font-size: 14px;
    color: red;
    padding: 0;
    margin: 0;
}

.slowGradualError {
    animation-duration: 8.5s;
    animation-name: showHide;
    animation-fill-mode: forwards;
}

.gradualError {
    animation-duration: 2s;
    animation-name: showHide;
    animation-fill-mode: forwards;
}

@keyframes showHide {
    from {
        opacity: 0;
    }
    15% {
        opacity: 100;
    }
    55% {
        opacity: 100;
    }
    to {
        opacity: 0;
    }
}

.checkboxWithError {
    display: flex;
    height: 50%;
    flex-direction: column;
    line-height: 0;
}

.wholeColumnStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 14px;
    border: 1px solid var(--borderColor);
    width: 240px;
    height: 500px;
}

.columnBackground {
    width: 100%;
    height: 100%;
    padding: 15px 0px;
    overflow-y: auto;
}

.columnCenterStyle {
    margin: 12px;
}

.smallHeader * {
    font-size: 19px !important;
    padding: 4px;
}

.columnGroupStyle {
    display: flex;
    justify-content: center;
    height: 100%;
}

.activeBackgroundColumn {
    background-color: rgb(245, 245, 245);
}

.smallerText {
    font-size: 12px;
}

.partyBox {
    user-select: none;
    padding: 12px;
    min-height: 50px;
    color: white;
    background-color: rgb(44, 53, 94);
    margin: 8px;
    border-radius: 4px;
}

.activeBackgroundPartyBox {
    background-color: black;
}

.unstyledSubheader {
    margin: 0;
    padding: 0;
    font-size: 12px !important;
    color: white !important;
    text-decoration: none;
    font-weight: normal;
}

.emptySpace {
    padding: 9px;
}

.twoBtns {
    width: 500px;
    margin: auto !important;
    padding-bottom: 60px;
}

.decoratedTextField {
    border-radius: 4px;
    border: 1px solid var(--borderColor);
    width: fit-content;
    margin: 40px auto 20px auto!important;
    padding: 12px 54px;
    box-shadow: 4px 4px 4px lightgrey;
}

.loadingCircle {
    position: relative;
    top: 100px
}

.higherLoadingCircle {
    position: relative;
    top: 50px
}

.hiddenInput {
    visibility: hidden;
}

.endSurvey {
    color: var(--main);
    display: block;
    margin-bottom: 15px;
    text-decoration: underline;
}

.subtitle {
    margin-bottom: 15px !important;
}