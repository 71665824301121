.guestTableHader{
	display:flex;
	flex-direction: row;
}

.editGuestListPage .pageNavigationButtons {
    display:			flex;
    flex-direction:		row;
    align-items:		center;
    justify-content:	space-between;
    padding: 0 10% 0 10% !important;
    max-width: 1000px;
    margin: auto;
}

.editGuestListPage .pageNavigationButtons .MuiButton-root {
    margin-inline: 6px;
    max-width: 200px;
    flex: 1;
}

.sendSurveyButton {
    display:			flex;
    flex-direction:		row;
    align-items:		end;
    justify-content:	flex-end;
    padding: 0 10% 0 10% !important;
    margin-inline: 6px;
    flex: 1;
    max-width: 1000px;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    #dragDropContainer{
        min-width: 250px;
    }
}