.policies {
    text-align: left;
    margin-bottom: 32px;
}

.contactInfo {
    color: inherit;
    margin: 16px 0px 2px 0px;
    text-decoration: none;
}

ul {
    list-style-type: none;
}

.footer a {
    /* background-color: white; */
    color: inherit;
    /* box-shadow: none !important; */
    margin: 4px;
}

.footer .footerInfo a {
    margin: 8px;
}

.policies a {
    word-break: break-word;
}