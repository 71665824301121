#edit-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.prevBtn {
    margin-right: 8px !important;
}

.pie {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    #edit-cards {
        flex-direction: column;
        align-content: space-between;
    }

    .smallCard, .bigCard {
        width: 50% !important;
        min-width: 400px;
    }
}

.dashCardHeader {
    background-color: var(--secondary);
    text-align: left;
    color: white;
}

.bigCard {
    width: 35%;
}

.flexCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.smallCard {
    width: 20%;
}

.dashCard {
    margin: 10px auto 0px auto;
    grid-template-rows: [header] 20% [main] 60% [btn] 20%;
    display: grid;
    height: 350px;
    text-align: left;
}

.summary p {
    text-align: left;
    font-size: 16px;
}

.summary {
    margin: 10px 10px 10px 16px;
    grid-row: main;
    grid-column: c;
}

.editButton {
    display: block;
    padding: 10px 15px 10px 15px;
    margin: auto;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: rgb(225, 225, 225);
    color: black;
    font-style: normal;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.1);
    cursor: default;
    width: 30%;
    min-width: 140px;
    grid-row: btn;
    grid-column: c;
}

  .editButton:hover {
    filter: brightness(90%);
    box-shadow: 4px 4px 8px rgba(0,0,0,0.3);
  }

  .pieChart {
      width: 120px;
      padding-right: 20px;
  }

  .cardFooter * {
      margin-left: 8px !important;
  }

@media only screen and (max-width: 768px) {
    .dashCard{
        min-width: 300px;
    }

    /* Start survey */
    #edit-cards #surveyPreview .innerBoxHeader .MuiCardHeader-title {
        font-size: 22px;
    }

    #edit-cards #surveyPreview .title {
        font-size: 28px;
    }

    #edit-cards #surveyPreview .survey {
        min-width: unset;
        width: 100%;

    }

    #edit-cards #surveyPreview .MuiCardContent-root {
        padding: 12px;
        height: calc(100vh - 140px);
        overflow-y: auto;
        box-sizing: border-box;
    }

    #edit-cards #surveyPreview>.MuiPaper-root {
        margin: 0 !important;
        position: fixed;
        top: 0;
    }

    #edit-cards #surveyPreview .MuiDataGrid-columnHeaderWrapper{
        padding: 0px;
        width: 100%;
    }

    #edit-cards #surveyPreview .MuiDataGrid-root {
        border: 0 !important
    }

    #edit-cards #surveyPreview .survey .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
        padding-left: 26px !important;
    }

    #edit-cards #surveyPreview .generalButton {
        margin: 60px 12px 22px 12px !important;
    }

    #edit-cards #surveyPreview .smallSearch {
        width: 100%;
    }
}
