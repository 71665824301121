.subgroupMember {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;;
}

.subgroupMember .MuiFormControl-root {
    max-width: 300px;
    margin: 0 !important;
}

.guestListTable .addButtonGroup{
	text-align: left;
}

.guestListTable .addButtonGroup button {
    width: 190px;
}

.guestListTable .nameCol,
.guestListTable .contactCol {
    width: 25%;
    padding-left: 15px;
    height: 56px;
}

.guestListTable .nameCol .MuiFormControl-root,
.guestListTable .contactCol .MuiFormControl-root{
	margin-left: -14px !important;
}

.guestListTable .firstColName,
.guestListTable .firstColContact{
	padding-left: 10px;
}

.guestListTable {
	max-width: 1000px;
	text-align: left;
	margin: 20px auto;
	border-radius: 8px;
	border: 2px solid var(--secondary);
	background-color: var(--secondary);
}

.resultTable {
	border-collapse: collapse;
	width: 100%;
	border-radius: 6px;
	overflow: hidden;
	border: none;
	background-color: white;
}


.subgroupMemberList {
	list-style: none;
	padding-inline: 38px 12px;
}

.guestListIcon {
	box-shadow: none !important;
}

.headingRow {
	background-color: var(--secondary);
	padding-right: 5px;
}

thead {
	color: white;
   	 height: 50px;
}

.firstCol {
	padding-left: 15px;
}

.lastCol{
	padding-right: 15px;
}

.midCol{
	padding-right: 15px;
}

tbody .groupSummary td {
	padding-top: 12px;
}

tbody tr:last-child.groupDropdown td {
    	padding-bottom: 12px;
}

.saveCheck {
	max-width: 1.25ch;
	opacity: 50%;
	padding-left: 3px;
}

.errorCheck {
	max-width: 1.25ch;
	opacity: 50%;
	padding-left: 3px;
	color: red;
}



/* tbody tr {
	border-left: 3px solid var(--secondary);
	border-right: 2px solid var(--secondary);
} */


@media only screen and (max-width: 768px) {
	.resultTable{
		table-layout: fixed;
		width: 100%;
		overflow-wrap: break-word;
	}

	.guestListTable .nameCol,
	.guestListTable .contactCol {
		max-width: 30%;
		width: unset;
	}

	.firstCol{
		padding-left: 0px;
		width: 5%;
	}

	.guestListTable .firstColName{
		padding-left: 0%;
		width: 50%;
		padding-right: 14px;
	}

	.guestListTable .sendSurveyColumnHeader{
		width: 12%;
		font-size: 12px;
	}

	.guestListTable .editColumnHeader{
		width: 10%;
		font-size: 12px;
	}
	.guestListTable .deleteColumnHeader{
		width: 14%;
		font-size: 12px;
	}

	.guestListTable .guestContact{
		color: rgb(133, 133, 133);
		margin-top: 4px;
		margin-bottom: 4px;
		margin-left: 8px;
		text-overflow: ellipsis;
		overflow: hidden;
		overflow-wrap: normal;
	}

	.guestListTable .groupSummary .firstCol .guestListIcon{
		padding-left: 3px;
	}

	.subtitle{
		min-width: 300px;
	}

	.guestListTable .midCol {
		padding: 2px;
		font-size: 12px;
	}

	.guestListTable .statusColumn {
		font-size: 12px;
	}
}